import React from 'react';
import { Translate } from 'react-i18nify';

export const SELECT_REPORT_TYPE = 'SELECT_REPORT_TYPE';
export const SELECT_REPORT_TIME_FILTER = 'SELECT_REPORT_TIME_FILTER';
export const SELECT_START_CUSTOM_REPORT_DATE =
  'SELECT_START_CUSTOM_REPORT_DATE';
export const SELECT_END_CUSTOM_REPORT_DATE = 'SELECT_END_CUSTOM_REPORT_DATE';
export const CHECK_REPORTS_TIMESTAMP = 'CHECK_REPORTS_TIMESTAMP';
export const CLEAR_RUN_REPORT = 'CLEAR_RUN_REPORT';
export const CHECK_REPORTS_TIMESTAMP_SUCCESS =
  'CHECK_REPORTS_TIMESTAMP_SUCCESS';
export const TOGGLE_ALL_SELECT_REPORT = 'TOGGLE_ALL_SELECT_REPORT';
export const RUN_MULTI_REPORT = 'RUN_MULTI_REPORT';
export const RUN_REPORT = 'RUN_REPORT';
export const SELECT_RUN_REPORT = 'SELECT_RUN_REPORT';
export const REPORT_IN_PROGRESS = 'REPORT_IN_PROGRESS';
export const REPORT_SUCCESS = 'REPORT_SUCCESS';
export const REPORT_ERROR = 'REPORT_ERROR';
export const UPDATE_REPORT_ROW = 'UPDATE_REPORT_ROW';
export const REPORT_FIRST_PAGE = 'REPORT_FIRST_PAGE';
export const REPORT_NEXT_PAGE = 'REPORT_NEXT_PAGE';
export const REPORT_PREV_PAGE = 'REPORT_PREV_PAGE';
export const REPORT_LAST_PAGE = 'REPORT_LAST_PAGE';
export const GET_SCHEDULE_REPORTS = 'GET_SCHEDULE_REPORTS';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_FAILURE = 'GET_REPORT_FAILURE';
export const OPEN_SCHEDULE_REPORT_MODAL = 'OPEN_SCHEDULE_REPORT_MODAL';
export const CLOSE_SCHEDULE_REPORT_MODAL = 'CLOSE_SCHEDULE_REPORT_MODAL';
export const SELECT_SCHEDULE_REPORT_TYPE = 'SELECT_SCHEDULE_REPORT_TYPE';
export const SELECT_SCHEDULE_REPORT_TIMEFRAME =
  'SELECT_SCHEDULE_REPORT_TIMEFRAME';
export const SELECT_SCHEDULE_REPORT_FREQUENCY =
  'SELECT_SCHEDULE_REPORT_FREQUENCY';
export const ON_CHANGE_RECIPIENTS = 'ON_CHANGE_RECIPIENTS';
export const SELECT_SCHEDULE_REPORT_HOUR = 'SELECT_SCHEDULE_REPORT_HOUR';
export const SELECT_SCHEDULE_REPORT_WEEK = 'SELECT_SCHEDULE_REPORT_WEEK';
export const SELECT_SCHEDULE_REPORT_DAY = 'SELECT_SCHEDULE_REPORT_DAY';
export const CREATE_REPORT = 'CREATE_REPORT';
export const SCHEDULE_REPORT_LOADING = 'SCHEDULE_REPORT_LOADING';
export const OPEN_EDIT_SCHEDULED_REPORT = 'OPEN_EDIT_SCHEDULED_REPORT';
export const CLOSE_EDIT_REPORT_MODAL = 'CLOSE_EDIT_REPORT_MODAL';
export const SET_EDIT_SCHEDULED_REPORT = 'SET_EDIT_SCHEDULED_REPORT';
export const EDIT_SCHEDULED_REPORT = 'EDIT_SCHEDULED_REPORT';
export const DELETE_SCHEDULED_REPORT = 'DELETE_SCHEDULED_REPORT';
export const TOGGLE_BASIC_REPORT = 'TOGGLE_BASIC_REPORT';

export const REPORT_DOWNLOAD_PDF = 'REPORT_DOWNLOAD_PDF';
export const REPORT_DOWNLOAD_PDF_SUCCESS = 'REPORT_DOWNLOAD_PDF_SUCCESS';
export const REPORT_DOWNLOAD_PDF_FAILURE = 'REPORT_DOWNLOAD_PDF_FAILURE';

export const REPORT_DOWNLOAD_CSV = 'REPORT_DOWNLOAD_CSV';
export const REPORT_DOWNLOAD_CSV_SUCCESS = 'REPORT_DOWNLOAD_CSV_SUCCESS';
export const REPORT_DOWNLOAD_CSV_FAILURE = 'REPORT_DOWNLOAD_CSV_FAILURE';

export const USER_REPORT_SUCCESS = 'USER_REPORT_SUCCESS';
export const OVERVIEW_REPORT_SUCCESS = 'OVERVIEW_REPORT_SUCCESS';
export const PER_USER_REPORT_SUCCESS = 'PER_USER_REPORT_SUCCESS';

export const SELECT_USER_FILTER = 'SELECT_USER_FILTER';
export const SELECT_SCHEDULE_REPORT_USER = 'SELECT_SCHEDULE_REPORT_USER';

export const SELECT_SCHEDULE_LOCATION_USER = 'SELECT_SCHEDULE_LOCATION_USER';

export const SELECT_LOCATION_FILTER = 'SELECT_LOCATION_FILTER';

export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS';

export const LOCATION_REPORT_SUCCESS = 'LOCATION_REPORT_SUCCESS';

export const categoryHeaders = {
  'blocked-categories': [
    'components.basicFiltering.name',
    'components.reporting.superCategory',
    'components.reporting.blockedRequests',
  ],
  'blocked-supercategories': [
    'components.reporting.superCategory',
    'components.reporting.blockedRequests',
  ],
};

export const reportTypes = [
  {
    key: 'basic-reports',
    label: <Translate value="components.reporting.webReport" />,
    type: 'label',
  },
  {
    key: 'blocked-supercategories',
    label: <Translate value="components.reporting.blockedSupercategories" />,
    subscriptions: ['dns'],
  },
  {
    key: 'blocked-categories',
    label: <Translate value="components.reporting.blockedCategories" />,
    subscriptions: ['dns'],
  },
  {
    key: 'location-reports',
    label: <Translate value="components.reporting.locationReports" />,
    subscriptions: ['dns'],
  },
  {
    key: 'user-reports',
    label: <Translate value="components.reporting.userReports" />,
    subscriptions: ['atp'],
  },
  {
    key: 'per-user-reports',
    label: <Translate value="components.reporting.perUserReports" />,
    subscriptions: ['atp'],
  },
  {
    key: 'account-reports',
    label: <Translate value="components.reporting.accountReport" />,
    type: 'label',
  },
  {
    key: 'overview-reports',
    label: <Translate value="components.reporting.overviewReports" />,
    subscriptions: ['account'],
  },
  {
    key: 'atp-reports',
    label: <Translate value="components.reporting.atpReport" />,
    type: 'label',
  },
  {
    key: 'all',
    label: <Translate value="components.reporting.all" />,
    subscriptions: ['atp'],
  },
  {
    key: 'endpoints',
    label: <Translate value="components.reporting.endpoints" />,
    subscriptions: ['atp'],
  },
  {
    key: 'users',
    label: <Translate value="components.reporting.users" />,
    subscriptions: ['atp'],
  },
  {
    key: 'files',
    label: <Translate value="components.reporting.files" />,
    subscriptions: ['atp'],
  },
];

export const timeOptions = [
  {
    key: 'day',
    label: <Translate value="shared.ranges.day" />,
  },
  {
    key: 'week',
    label: <Translate value="shared.ranges.week" />,
  },
  {
    key: 'month',
    label: <Translate value="shared.ranges.month" />,
  },
];

export const frequencyOptions = [
  {
    key: 'hourly',
    label: <Translate value="shared.frequency.hourly" />,
  },
  {
    key: 'daily',
    label: <Translate value="shared.frequency.daily" />,
  },
  {
    key: 'weekly',
    label: <Translate value="shared.frequency.weekly" />,
  },
  {
    key: 'biweekly',
    label: <Translate value="shared.frequency.biweekly" />,
  },
  {
    key: 'monthly',
    label: <Translate value="shared.frequency.monthly" />,
  },
];

export const weekOptions = [
  {
    key: 7,
    label: <Translate value="shared.sunday" />,
  },
  {
    key: 1,
    label: <Translate value="shared.monday" />,
  },
  {
    key: 2,
    label: <Translate value="shared.tuesday" />,
  },
  {
    key: 3,
    label: <Translate value="shared.wednesday" />,
  },
  {
    key: 4,
    label: <Translate value="shared.thursday" />,
  },
  {
    key: 5,
    label: <Translate value="shared.friday" />,
  },
  {
    key: 6,
    label: <Translate value="shared.saturday" />,
  },
];

export const hourOptions = [
  {
    key: 1,
    label: '1:00',
  },
  {
    key: 2,
    label: '2:00',
  },
  {
    key: 3,
    label: '3:00',
  },
  {
    key: 4,
    label: '4:00',
  },
  {
    key: 5,
    label: '5:00',
  },
  {
    key: 6,
    label: '6:00',
  },
  {
    key: 7,
    label: '7:00',
  },
  {
    key: 8,
    label: '8:00',
  },
  {
    key: 9,
    label: '9:00',
  },
  {
    key: 10,
    label: '10:00',
  },
  {
    key: 11,
    label: '11:00',
  },
  {
    key: 12,
    label: '12:00',
  },
  {
    key: 13,
    label: '13:00',
  },
  {
    key: 14,
    label: '14:00',
  },
  {
    key: 15,
    label: '15:00',
  },
  {
    key: 16,
    label: '16:00',
  },
  {
    key: 17,
    label: '17:00',
  },
  {
    key: 18,
    label: '18:00',
  },
  {
    key: 19,
    label: '19:00',
  },
  {
    key: 20,
    label: '20:00',
  },
  {
    key: 21,
    label: '21:00',
  },
  {
    key: 22,
    label: '22:00',
  },
  {
    key: 23,
    label: '23:00',
  },
  {
    key: 0,
    label: '24:00',
  },
];

export const dayOptions = [
  {
    key: 1,
    label: '1',
  },
  {
    key: 2,
    label: '2',
  },
  {
    key: 3,
    label: '3',
  },
  {
    key: 4,
    label: '4',
  },
  {
    key: 5,
    label: '5',
  },
  {
    key: 6,
    label: '6',
  },
  {
    key: 7,
    label: '7',
  },
  {
    key: 8,
    label: '8',
  },
  {
    key: 9,
    label: '9',
  },
  {
    key: 10,
    label: '10',
  },
  {
    key: 11,
    label: '11',
  },
  {
    key: 12,
    label: '12',
  },
  {
    key: 13,
    label: '13',
  },
  {
    key: 14,
    label: '14',
  },
  {
    key: 15,
    label: '15',
  },
  {
    key: 16,
    label: '16',
  },
  {
    key: 17,
    label: '17',
  },
  {
    key: 18,
    label: '18',
  },
  {
    key: 19,
    label: '19',
  },
  {
    key: 20,
    label: '20',
  },
  {
    key: 21,
    label: '21',
  },
  {
    key: 22,
    label: '22',
  },
  {
    key: 23,
    label: '23',
  },
  {
    key: 24,
    label: '24',
  },
  {
    key: 25,
    label: '25',
  },
  {
    key: 26,
    label: '26',
  },
  {
    key: 27,
    label: '27',
  },
  {
    key: 28,
    label: '28',
  },
  {
    key: 29,
    label: '29',
  },
  {
    key: 30,
    label: '30',
  },
  {
    key: 31,
    label: '31',
  },
];

export const toggleAllSelectReport = reports => ({
  type: TOGGLE_ALL_SELECT_REPORT,
  reports,
});

export const downloadPdf = (
  reportType,
  range,
  timezone,
  name,
  title,
  rangeText
) => ({
  type: REPORT_DOWNLOAD_PDF,
  reportType,
  range,
  timezone,
  name,
  title,
  rangeText,
});

export const downloadPdfSuccess = () => ({
  type: REPORT_DOWNLOAD_PDF_SUCCESS,
});

export const downloadPdfFailure = error => ({
  type: REPORT_DOWNLOAD_PDF_FAILURE,
  error,
});

export const downloadCsv = (reportType, range) => ({
  type: REPORT_DOWNLOAD_CSV,
  reportType,
  range,
});

export const downloadCsvSuccess = () => ({
  type: REPORT_DOWNLOAD_CSV_SUCCESS,
});

export const downloadCsvFailure = error => ({
  type: REPORT_DOWNLOAD_CSV_FAILURE,
  error,
});

export const clearRunReport = () => ({
  type: CLEAR_RUN_REPORT,
});

export const handleSelectReport = report => ({
  type: SELECT_RUN_REPORT,
  report,
});

export const checkReportsTimestampSuccess = lastSeen => ({
  type: CHECK_REPORTS_TIMESTAMP_SUCCESS,
  lastSeen,
});

export const checkReportsTimestamp = () => ({
  type: CHECK_REPORTS_TIMESTAMP,
});

export const runMultiReports = () => ({
  type: RUN_MULTI_REPORT,
});

export const deleteReport = () => ({
  type: DELETE_SCHEDULED_REPORT,
});

export const editReport = () => ({
  type: EDIT_SCHEDULED_REPORT,
});

export const setEditData = (data, options, userOptions, locationOptions) => ({
  type: SET_EDIT_SCHEDULED_REPORT,
  data,
  options,
  userOptions,
  locationOptions,
});

export const openEditReport = () => ({
  type: OPEN_EDIT_SCHEDULED_REPORT,
});

export const loadingReports = () => ({
  type: SCHEDULE_REPORT_LOADING,
});

export const createReport = () => ({
  type: CREATE_REPORT,
});

export const selectDay = (key, index) => ({
  type: SELECT_SCHEDULE_REPORT_DAY,
  key,
  index,
});

export const selectUser = (key, value) => ({
  type: SELECT_SCHEDULE_REPORT_USER,
  key,
  value,
});

export const selectLocation = (key, index) => ({
  type: SELECT_SCHEDULE_LOCATION_USER,
  key,
  index,
});

export const selectWeek = (key, index) => ({
  type: SELECT_SCHEDULE_REPORT_WEEK,
  key,
  index,
});

export const selectHour = (key, index) => ({
  type: SELECT_SCHEDULE_REPORT_HOUR,
  key,
  index,
});

export const onChangeRecipients = (key, value) => ({
  type: ON_CHANGE_RECIPIENTS,
  key,
  value,
});

export const selectScheduleReportFrequency = (key, index) => ({
  type: SELECT_SCHEDULE_REPORT_FREQUENCY,
  key,
  index,
});

export const selectScheduleReport = (key, index, options) => ({
  type: SELECT_SCHEDULE_REPORT_TYPE,
  key,
  index,
  options,
});

export const selectScheduleReportTimeframe = (key, index) => ({
  type: SELECT_SCHEDULE_REPORT_TIMEFRAME,
  key,
  index,
});

export const closeEditReportModal = () => ({
  type: CLOSE_EDIT_REPORT_MODAL,
});

export const closeScheduleReportModal = () => ({
  type: CLOSE_SCHEDULE_REPORT_MODAL,
});

export const openScheduleReportModal = mainOptions => ({
  type: OPEN_SCHEDULE_REPORT_MODAL,
  options: mainOptions,
});

export const getReportSuccess = (reports, lastSeen) => ({
  type: GET_REPORT_SUCCESS,
  reports,
  lastSeen,
});

export const getReportFailure = error => ({
  type: GET_REPORT_FAILURE,
  error,
});

export const getScheduleReports = () => ({
  type: GET_SCHEDULE_REPORTS,
});

export const firstPage = () => ({
  type: REPORT_FIRST_PAGE,
});

export const nextPage = () => ({
  type: REPORT_NEXT_PAGE,
});

export const prevPage = () => ({
  type: REPORT_PREV_PAGE,
});

export const lastPage = () => ({
  type: REPORT_LAST_PAGE,
});

export const updateRows = rows => ({
  type: UPDATE_REPORT_ROW,
  rows,
});

export const runReport = (type, range, rows, index, id) => ({
  type: RUN_REPORT,
  reportSelect: type,
  range,
  rows,
  index,
  id,
});

export const runningReport = () => ({
  type: REPORT_IN_PROGRESS,
});

const _formatReportData = data => {
  let formatData = { ...data };
  if (data.reportType === 'blocked-categories') {
    let cats = data.categories;
    let wcs_result = data.wcs;
    let retData = [];
    let name = 'Unknown';
    let supercategory = 'Unknown';
    let parentcat = -1;
    let key;
    let i = 0;

    for (i = 0; i < cats.length; i++) {
      name = 'Unknown';
      supercategory = 'Unknown';
      parentcat = -1;
      key = cats[i]['key'];

      if (wcs_result['categories'][key]) {
        name = wcs_result['categories'][key]['display'];
        parentcat = wcs_result['categories'][key]['parent'];
      }

      if (parentcat !== -1 && wcs_result['supercategories'][parentcat]) {
        supercategory = wcs_result['supercategories'][parentcat];
      }

      retData.push({
        count: cats[i]['doc_count'],
        name: name,
        supercategory: supercategory,
      });
    }

    formatData['graphData'] = retData;
    formatData['tableData'] = retData;
    formatData['total'] = retData.length;
  } else if (data.reportType === 'blocked-supercategories') {
    let cats = data['categories'];
    let wcs_result = data['wcs'];
    let retData = [];
    let name = 'Unknown';
    let key;
    let i = 0;

    for (i = 0; i < cats.length; i++) {
      name = 'Unknown';
      key = cats[i]['key'];

      if (wcs_result['supercategories'][key]) {
        name = wcs_result['supercategories'][key];
      }

      retData.push({
        count: cats[i]['doc_count'],
        name: name,
      });
    }

    formatData['graphData'] = retData;
    formatData['tableData'] = retData;
    formatData['total'] = retData.length;
  } else {
    formatData['graphData'] = data['aggregations'][data.reportType]['buckets'];
    formatData['tableData'] = data.hits.hits;
    formatData['total'] = data.hits.total;
  }

  return formatData;
};

export const runReportSuccess = data => {
  const formatData = _formatReportData(data);
  return {
    type: REPORT_SUCCESS,
    data: formatData,
  };
};

const _formatUserReportData = data => {
  let formatData = { ...data };
  let userActivity =
    data['user_activity']['aggregations']['top_users_activity']['buckets'];
  let blockedUsers =
    data['blocked_activity']['aggregations']['users_blocked']['buckets'];
  let blockedCategories =
    data['blocked_activity']['aggregations']['categories_blocked']['buckets'];

  let directory = data['directory'];
  const userDirectory = directory['users'];
  let i = 0;
  let userActivityRetData = [];
  let blockedUserActivityRetData = [];
  let blockedCatRetData = [];
  let name;
  let userDirectoryMap = {};
  let wcs_result = data.wcs;

  for (let y = 0; y < userDirectory.length; y++) {
    if (userDirectory[y]['uuid']) {
      userDirectoryMap[userDirectory[y]['uuid']] =
        userDirectory[y]['display_name'];
    } else {
      userDirectoryMap[userDirectory[y]['id']] = userDirectory[y]['name'];
    }
  }

  for (i = 0; i < userActivity.length; i++) {
    name = userDirectoryMap[userActivity[i]['key']] || 'No longer exists';

    userActivityRetData.push({
      count: userActivity[i]['doc_count'],
      name: name,
    });
  }

  for (i = 0; i < blockedUsers.length; i++) {
    name = userDirectoryMap[blockedUsers[i]['key']] || 'No longer exists';

    blockedUserActivityRetData.push({
      count: blockedUsers[i]['doc_count'],
      name: name,
    });
  }

  for (i = 0; i < blockedCategories.length; i++) {
    let key = blockedCategories[i]['key'];
    if (wcs_result['categories'][key]) {
      name = wcs_result['categories'][key]['display'];
    }

    blockedCatRetData.push({
      count: blockedCategories[i]['doc_count'],
      name: name,
    });
  }

  formatData['activeUsers'] = userActivityRetData;
  formatData['blockedUsers'] = blockedUserActivityRetData;
  formatData['blockedCategories'] = blockedCatRetData;

  return formatData;
};

export const runUserReportSuccess = data => {
  const formatData = _formatUserReportData(data);
  return {
    type: USER_REPORT_SUCCESS,
    data: formatData,
  };
};

const _formatOverviewReportData = data => {
  let formatData = { ...data };
  let security_denied_category =
    data['security_denied_category']['aggregations']['categories_blocked'][
      'buckets'
    ];
  let security_allowed_category =
    data['security_allowed_category']['aggregations']['categories_blocked'][
      'buckets'
    ];
  let productivity_denied_category =
    data['productivity_denied_category']['aggregations']['categories_blocked'][
      'buckets'
    ];
  let productivity_allowed_category =
    data['productivity_allowed_category']['aggregations']['categories_blocked'][
      'buckets'
    ];

  let compliance_denied_category =
    data['compliance_denied_category']['aggregations']['categories_blocked'][
      'buckets'
    ];
  let compliance_allowed_category =
    data['compliance_allowed_category']['aggregations']['categories_blocked'][
      'buckets'
    ];

  let blockedUsers =
    data['blocked_activity']['aggregations']['users_blocked']['buckets'];

  let directory = data['directory'];
  const userDirectory = directory['users'];

  let blockedUserActivityRetData = [];
  let userDirectoryMap = {};

  let security_allowedCategoryRetData = [];
  let security_deniedCategoryRetData = [];

  let productivity_allowedCategoryRetData = [];
  let productivity_deniedCategoryRetData = [];

  let compliance_allowedCategoryRetData = [];
  let compliance_deniedCategoryRetData = [];

  let i = 0;
  let wcs_result = data.wcs;
  let name;

  // construct user map:

  for (let y = 0; y < userDirectory.length; y++) {
    if (userDirectory[y]['uuid']) {
      userDirectoryMap[userDirectory[y]['uuid']] =
        userDirectory[y]['display_name'];
    } else {
      userDirectoryMap[userDirectory[y]['id']] = userDirectory[y]['name'];
    }
  }

  // security- denied_category
  for (i = 0; i < security_denied_category.length; i++) {
    let key = security_denied_category[i]['key'];
    if (wcs_result['categories'][key]) {
      name = wcs_result['categories'][key]['display'];
    }

    security_deniedCategoryRetData.push({
      count: security_denied_category[i]['doc_count'],
      name: name,
    });
  }

  // security- allowed_category
  for (i = 0; i < security_allowed_category.length; i++) {
    let key = security_allowed_category[i]['key'];
    if (wcs_result['categories'][key]) {
      name = wcs_result['categories'][key]['display'];
    }

    security_allowedCategoryRetData.push({
      count: security_allowed_category[i]['doc_count'],
      name: name,
    });
  }

  //productivity - denied_category

  for (i = 0; i < productivity_denied_category.length; i++) {
    let key = productivity_denied_category[i]['key'];
    if (wcs_result['categories'][key]) {
      name = wcs_result['categories'][key]['display'];
    }

    productivity_deniedCategoryRetData.push({
      count: productivity_denied_category[i]['doc_count'],
      name: name,
    });
  }

  // productivity - allowed_category

  for (i = 0; i < productivity_allowed_category.length; i++) {
    let key = productivity_allowed_category[i]['key'];
    if (wcs_result['categories'][key]) {
      name = wcs_result['categories'][key]['display'];
    }

    productivity_allowedCategoryRetData.push({
      count: productivity_allowed_category[i]['doc_count'],
      name: name,
    });
  }

  //compliance - denied_category

  for (i = 0; i < compliance_denied_category.length; i++) {
    let key = compliance_denied_category[i]['key'];
    if (wcs_result['categories'][key]) {
      name = wcs_result['categories'][key]['display'];
    }

    compliance_deniedCategoryRetData.push({
      count: compliance_denied_category[i]['doc_count'],
      name: name,
    });
  }

  //compliance - allowed_category

  for (i = 0; i < compliance_allowed_category.length; i++) {
    let key = compliance_allowed_category[i]['key'];
    if (wcs_result['categories'][key]) {
      name = wcs_result['categories'][key]['display'];
    }

    compliance_allowedCategoryRetData.push({
      count: compliance_allowed_category[i]['doc_count'],
      name: name,
    });
  }

  for (i = 0; i < blockedUsers.length; i++) {
    name = userDirectoryMap[blockedUsers[i]['key']] || 'No longer exists';

    blockedUserActivityRetData.push({
      count: blockedUsers[i]['doc_count'],
      name: name,
    });
  }

  let blocked_vulnerabilitiesRetData = [];
  blocked_vulnerabilitiesRetData.push({
    doc_count: data['aggregations']['hits']['total'],
    key: 'Threats',
  });

  let blocked_endpointsRetData = [];
  let blocked_endpoints =
    data['aggregations']['aggregations']['endpoints']['buckets'];
  for (i = 0; i < blocked_endpoints.length; i++) {
    blocked_endpointsRetData.push({
      count: blocked_endpoints[i]['lastFile']['hits']['total'],
      name:
        blocked_endpoints[i]['lastFile']['hits']['hits'][0]['_source']['beat'][
          'hostname'
        ],
    });
  }
  formatData['security_denied_category'] = security_deniedCategoryRetData;
  formatData['security_allowed_category'] = security_allowedCategoryRetData;
  formatData[
    'productivity_denied_category'
  ] = productivity_deniedCategoryRetData;
  formatData[
    'productivity_allowed_category'
  ] = productivity_allowedCategoryRetData;
  formatData['compliance_denied_category'] = compliance_deniedCategoryRetData;
  formatData['compliance_allowed_category'] = compliance_allowedCategoryRetData;
  formatData['blocked_activity'] = blockedUserActivityRetData;
  formatData['blocked_vulnerabilities'] = blocked_vulnerabilitiesRetData;
  formatData['blocked_endpoints'] = blocked_endpointsRetData;
  return formatData;
};

export const runOverviewReportSuccess = data => {
  const formatData = _formatOverviewReportData(data);
  return {
    type: OVERVIEW_REPORT_SUCCESS,
    data: formatData,
  };
};

const _formatPerUserReportData = data => {
  let formatData = { ...data };
  let activity =
    data['user_activity']['aggregations']['top_actions']['buckets'];
  let visitedDomains =
    data['user_activity']['aggregations']['top_domains']['buckets'];
  let blockedDomains =
    data['blocked_activity']['aggregations']['top_domains']['buckets'];

  let activityRetData = [];
  for (let i = 0; i < activity.length; i++) {
    activityRetData.push({
      name: activity[i]['key'],
      count: activity[i]['doc_count'],
    });
  }

  let visitedDomainsRetData = [];
  for (let i = 0; i < visitedDomains.length; i++) {
    visitedDomainsRetData.push({
      name: visitedDomains[i]['key'],
      count: visitedDomains[i]['doc_count'],
    });
  }

  let blockedDomainsRetData = [];
  for (let i = 0; i < blockedDomains.length; i++) {
    blockedDomainsRetData.push({
      name: blockedDomains[i]['key'],
      count: blockedDomains[i]['doc_count'],
    });
  }

  formatData['activity'] = activityRetData;
  formatData['visitedDomains'] = visitedDomainsRetData;
  formatData['blockedDomains'] = blockedDomainsRetData;

  return formatData;
};
export const runPerUserReportSuccess = data => {
  const formatData = _formatPerUserReportData(data);
  return {
    type: PER_USER_REPORT_SUCCESS,
    data: formatData,
  };
};

const _formatLocationReportData = data => {
  let formatData = { ...data };
  let supercategoriesBlocked =
    data['location_activity']['aggregations']['supercategories_blocked'][
      'buckets'
    ];
  let categoriesBlocked =
    data['location_activity']['aggregations']['categories_blocked']['buckets'];
  let wcs_result = data.wcs;

  let supercatRetData = [];
  for (let i = 0; i < supercategoriesBlocked.length; i++) {
    let key = supercategoriesBlocked[i]['key'];
    let name = key;
    if (wcs_result['supercategories'][key]) {
      name = wcs_result['supercategories'][key];
    }
    supercatRetData.push({
      name: name,
      count: supercategoriesBlocked[i]['doc_count'],
    });
  }

  let categoriesBlockedRetData = [];
  for (let i = 0; i < categoriesBlocked.length; i++) {
    let key = categoriesBlocked[i]['key'];
    let name = key;
    if (wcs_result['categories'][key]) {
      name = wcs_result['categories'][key]['display'];
    }
    categoriesBlockedRetData.push({
      name: name,
      count: categoriesBlocked[i]['doc_count'],
    });
  }

  formatData['supercategoriesBlocked'] = supercatRetData;
  formatData['categoriesBlocked'] = categoriesBlockedRetData;

  return formatData;
};
export const runLocationSuccess = data => {
  const formatData = _formatLocationReportData(data);
  return {
    type: LOCATION_REPORT_SUCCESS,
    data: formatData,
  };
};

export const runReportError = error => ({
  type: REPORT_ERROR,
  error,
});

export const selectReportType = (index, mainOptions) => ({
  type: SELECT_REPORT_TYPE,
  value: mainOptions[index]['key'],
  index,
});

export const selectTimeFilter = index => ({
  type: SELECT_REPORT_TIME_FILTER,
  value: timeOptions[index]['key'],
  index,
});

export const selectLocationFilter = index => ({
  type: SELECT_LOCATION_FILTER,
  index,
});

export const selectUserFilter = index => ({
  type: SELECT_USER_FILTER,
  index,
});
export const selectStartCustomDate = date => ({
  type: SELECT_START_CUSTOM_REPORT_DATE,
  date,
});

export const selectEndCustomDate = date => ({
  type: SELECT_END_CUSTOM_REPORT_DATE,
  date,
});

export const deleteReportSuccess = () => ({
  type: DELETE_REPORT_SUCCESS,
});

export const basicFilterToggle = (report, index) => ({
  type: TOGGLE_BASIC_REPORT,
  report,
  index,
});
