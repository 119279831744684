import { createSelector } from 'reselect';

const getChanges = state => state.agentSettings.changes;
const getAgentSettings = state => state.agentSettings;

const getChangedFields = createSelector(
  [getChanges],
  changes => Object.keys(changes)
);

export { getChangedFields, getAgentSettings };
