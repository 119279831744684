import { connect } from 'react-redux';
import ReportModal from '../../components/reports/ReportModal';
import {
  getFilteredTypes,
  getUserOptions,
  getLocationOptions,
} from '../../selectors/reports';

import {
  closeScheduleReportModal,
  timeOptions,
  frequencyOptions,
  hourOptions,
  dayOptions,
  weekOptions,
  selectScheduleReport,
  selectScheduleReportTimeframe,
  selectScheduleReportFrequency,
  selectHour,
  selectDay,
  selectWeek,
  onChangeRecipients,
  createReport,
  selectUser,
  selectLocation,
} from '../../actions/reports';

const mapStateToProps = (state, ownProps) => ({
  open: state.reports.modal.open,
  reportIndex: state.reports.modal.reportIndex,
  timeframeIndex: state.reports.modal.timeframeIndex,
  frequencyIndex: state.reports.modal.frequencyIndex,
  hourIndex: state.reports.modal.hourIndex,
  weekIndex: state.reports.modal.weekIndex,
  dayIndex: state.reports.modal.dayIndex,
  recipients: state.reports.modal.recipients,
  title: 'Schedule Report',
  reportOptions: getFilteredTypes(state),
  timeOptions: timeOptions,
  frequencyOptions: frequencyOptions,
  hourOptions: hourOptions,
  weekOptions: weekOptions,
  dayOptions: dayOptions,
  locationOptions: getLocationOptions(state),
  userOptions: getUserOptions(state),
  locationSelectIndex: state.reports.modal.locationSelectIndex,
  userSelectIndex: state.reports.modal.userSelectIndex,
  timezone: state.account.time_zone,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClose: () => {
    dispatch(closeScheduleReportModal());
  },
  selectReport: (index, options) => {
    dispatch(selectScheduleReport('modal', index, options));
  },
  selectTimeframe: index => {
    dispatch(selectScheduleReportTimeframe('modal', index));
  },
  selectFrequency: index => {
    dispatch(selectScheduleReportFrequency('modal', index));
  },
  onChangeRecipients: value => {
    dispatch(onChangeRecipients('modal', value));
  },
  selectHour: index => {
    dispatch(selectHour('modal', index));
  },
  selectWeek: index => {
    dispatch(selectWeek('modal', index));
  },
  selectDay: index => {
    dispatch(selectDay('modal', index));
  },
  userSelectHandler: index => {
    dispatch(selectUser('modal', index));
  },
  locationSelectHandler: index => {
    dispatch(selectLocation('modal', index));
  },
  onSave: () => {
    dispatch(createReport());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportModal);
