import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Translate, translate } from 'react-i18nify';
import Button from '@material-ui/core/Button';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';

import ReportModal from '../../containers/reports/Modal';
import CudaMenu from '../CudaMenu';
import Paper from '../CudaPaper';

const styles = {
  headers: {
    height: '14px',
    width: '64px',
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
    lineHeight: '14px',
  },
  paper: {
    padding: '20px',
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  reportBtn: {
    height: '32px',
    lineHeight: '1px',
    margin: '0 16px 0 0',
    padding: '8px 21px',
    fontWeight: '200',
  },
  wrapper: {
    display: 'inline-flex',
    flexDirection: 'column',
    margin: '8px',
  },
  userWrapper: {
    display: 'inline-flex',
    flexDirection: 'column',
    margin: '8px',
    width: '200px',
  },
  buttonWrapper: {
    display: 'inline-flex',
    margin: '8px',
  },
  border: {
    borderRadius: 2,
    backgroundColor: '#FFFFFF',
    border: '1px solid #DDDDDD',
    width: '200px',
  },
};

const customStyles = {
  control: base => ({
    ...base,
    border: 'none',
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    fontSize: '14px',
    color: '#555555',
  }),
  placeholder: base => ({
    ...base,
    color: '#555555',
    opacity: '0.5',
    margin: '0',
    cursor: 'text',
  }),
  option: base => ({
    ...base,
    fontSize: '14px',
    color: '#555555',
  }),
  singleValue: base => ({
    ...base,
    fontSize: '14px',
    color: '#555555',
  }),
  input: base => ({
    ...base,
    margin: '2px 0',
  }),
};

let SelectTimeoutPtr;

const NoOptionsMessage = props => {
  return (
    <div data-test-id="no-option">
      <components.NoOptionsMessage {...props} />
    </div>
  );
};

class Selector extends Component {
  componentDidMount = () => {
    const { init, mainOptions } = this.props;
    init(mainOptions);
  };

  render() {
    const {
      classes,
      mainOptions,
      timeOptions,
      userOptions,
      locationOptions,
      mainSelectHandler,
      timeSelectHandler,
      executeHandler,
      locationSelectHandler,
      locationSelectIndex,
      userSelectHandler,
      userSelectIndex,
      mainSelectIndex,
      timeSelectIndex,
      openModal,
      reportSelect,
      timeSelect,
      rows,
      index,
    } = this.props;

    return (
      <Paper>
        <div className={classes.paper}>
          <div className={classes.wrapper}>
            <Translate
              value="shared.timeFrame"
              tag="div"
              className={classes.headers}
            />
            <CudaMenu
              options={timeOptions}
              onChange={timeSelectHandler}
              selectedIndex={timeSelectIndex}
            />
          </div>
          <div className={classes.wrapper}>
            <Translate
              value="shared.reportType"
              tag="div"
              className={classes.headers}
            />
            <CudaMenu
              options={mainOptions}
              onChange={index => {
                mainSelectHandler(index, mainOptions);
              }}
              selectedIndex={mainSelectIndex}
            />
          </div>
          {mainOptions[mainSelectIndex]['key'] === 'per-user-reports' && (
            <div className={classes.userWrapper}>
              <Translate
                value="shared.users"
                tag="div"
                className={classes.headers}
              />
              <div className={classes.border}>
                <AsyncSelect
                  inputId="selection-wrapper-input"
                  isDisabled={false}
                  styles={customStyles}
                  isClearable={false}
                  placeholder=<span data-test-id="filter-search-placeholder">
                    {translate(`shared.usersPlaceholder`)}
                  </span>
                  loadOptions={(input, callback) => {
                    clearTimeout(SelectTimeoutPtr);
                    SelectTimeoutPtr = setTimeout(() => {
                      callback(
                        userOptions
                          .filter(a =>
                            a.label.toLowerCase().includes(input.toLowerCase())
                          )
                          .slice(0, 100)
                      );
                    }, 1000);
                  }}
                  defaultOptions={userOptions.slice(0, 100)}
                  options={
                    userOptions.length > 100
                      ? userOptions.slice(0, 100)
                      : userOptions
                  }
                  className={classes.selectDropdown}
                  value={userSelectIndex}
                  components={{ NoOptionsMessage }}
                  getValue={value => {}}
                  onChange={selectedOptions => {
                    userSelectHandler(selectedOptions);
                  }}
                />
              </div>
            </div>
          )}
          {mainOptions[mainSelectIndex]['key'] === 'location-reports' && (
            <div className={classes.wrapper}>
              <Translate
                value="shared.locations"
                tag="div"
                className={classes.headers}
              />
              <CudaMenu
                options={locationOptions}
                onChange={locationSelectHandler}
                selectedIndex={locationSelectIndex}
              />
            </div>
          )}
          <div className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                console.log('the report select: ', reportSelect);
                let id = null;
                if (reportSelect === 'per-user-reports') {
                  id = userSelectIndex['key'];
                } else if (reportSelect === 'location-reports') {
                  id = locationOptions[locationSelectIndex]['key'];
                }
                executeHandler(reportSelect, timeSelect, rows, index, id);
              }}
              className={classes.reportBtn}
              disabled={false}
            >
              <Translate value="shared.view" />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                openModal(mainOptions);
              }}
              className={classes.reportBtn}
              disabled={false}
            >
              <Translate value="shared.schedule" />
            </Button>
          </div>
          <ReportModal />
        </div>
      </Paper>
    );
  }
}

Selector.propTypes = {
  classes: PropTypes.object.isRequired,
  mainOptions: PropTypes.array.isRequired,
  timeOptions: PropTypes.array.isRequired,
  userOptions: PropTypes.array.isRequired,
  locationOptions: PropTypes.array.isRequired,
  mainSelectHandler: PropTypes.func.isRequired,
  timeSelectHandler: PropTypes.func.isRequired,
  userSelectHandler: PropTypes.func.isRequired,
  init: PropTypes.func.isRequired,
  executeHandler: PropTypes.func.isRequired,
  userSelectIndex: PropTypes.number.isRequired,
  mainSelectIndex: PropTypes.number.isRequired,
  timeSelectIndex: PropTypes.number.isRequired,
  openModal: PropTypes.func.isRequired,
  reportSelect: PropTypes.string.isRequired,
  timeSelect: PropTypes.string.isRequired,
  rows: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default withStyles(styles)(Selector);
