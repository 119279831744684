import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-i18nify';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import RuleLink from './RuleLink';
import ActionCell from './ActionCell';

const LogDetails = ({ classes, log }) => (
  <Grid
    container
    spacing={1}
    className={classes.container}
    data-test-id="log-detail-wrapper"
  >
    <Grid item xs={4} data-test-id="log-detail-wrapper">
      <Translate
        data-test-id="log-details"
        value="components.wfLogs.logDetails"
        className={classes.header}
        tag="div"
      />
      <div className={classes.detail} data-test-id="log-date">
        <span className={classes.label}>
          <Translate value="components.wfLogs.date" />
        </span>
        <span className={classes.value}>{log.date}</span>
      </div>
      <div className={classes.detail} data-test-id="log-action">
        <span className={classes.label}>
          <Translate value="components.wfLogs.action" />
        </span>
        <span className={classes.value}>
          <ActionCell action={log.action.toLowerCase()} />
        </span>
      </div>
      <div className={classes.detail} data-test-id="log-rule">
        <span className={classes.label}>
          <Translate value="components.wfLogs.rule" />
        </span>
        <span className={classes.value}>
          {(log.action_reason && !log.action_reason.includes('Security')) ||
          !log.action_reason ? (
            <RuleLink
              rule={log.type === 'wca' ? log.rule_id : log.location_id}
              type={log.type}
            />
          ) : (
            <Translate value="shared.security" />
          )}
        </span>
      </div>
    </Grid>
    <Grid item xs={4}>
      <Translate
        data-test-id="log-source-details"
        value="components.wfLogs.sourceDetails"
        className={classes.header}
        tag="div"
      />
      <div className={classes.detail} data-test-id="log-user-id">
        <span className={classes.label}>
          <Translate value="components.wfLogs.userId" />
        </span>
        <span className={classes.value}>{log.source}</span>
      </div>
      <div className={classes.detail} data-test-id="log-source-ip">
        <span className={classes.label}>
          <Translate value="components.wfLogs.sourceIP" />
        </span>
        <span className={classes.value}>{log.src_ip || log.source_ip}</span>
      </div>
      {log.type === 'wca' && (
        <div className={classes.detail} data-test-id="log-user-agent">
          <span className={classes.label}>
            <Translate value="components.wfLogs.userAgent" />
          </span>
          <span className={classes.value}>{log.user_agent}</span>
        </div>
      )}
      {log.type === 'wca' && (
        <div className={classes.detail} data-test-id="log-referrer">
          <span className={classes.label}>
            <Translate value="components.wfLogs.referrer" />
          </span>
          <span className={classes.value}>{log.referrer}</span>
        </div>
      )}
    </Grid>
    <Grid item xs={4}>
      <Translate
        data-test-id="log-destination-details"
        value="components.wfLogs.destinationDetails"
        className={classes.header}
        tag="div"
      />
      <div className={classes.detail} data-test-id="log-category">
        <span className={classes.label}>
          <Translate value="components.wfLogs.category" />
        </span>
        {log.action_reason && log.action_reason.includes('Office 365') ? (
          <span className={classes.value}>
            <Translate value="components.smbAccountSettings.O365" />
          </span>
        ) : (
          <span className={classes.value}>{log.categories}</span>
        )}
      </div>
      <div className={classes.detail} data-test-id="log-url">
        <span className={classes.label}>
          <Translate value="components.wfLogs.url" />
        </span>
        <span className={classes.value}>{log.url}</span>
      </div>
      <div className={classes.detail} data-test-id="log-destination-ip">
        <span className={classes.label}>
          <Translate value="components.wfLogs.destinationIP" />
        </span>
        <span className={classes.value}>{log.dst_ip}</span>
      </div>
      {log.type === 'wca' && (
        <div className={classes.detail} data-test-id="log-wca">
          <span className={classes.label}>
            <Translate value="components.wfLogs.contentType" />
          </span>
          <span className={classes.value}>{log.content_type}</span>
        </div>
      )}
    </Grid>
  </Grid>
);

LogDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  log: PropTypes.object.isRequired,
};

const styles = {
  container: {
    padding: '16px 24px',
    '& > div': {
      paddingRight: '24px',
      '&:last-child': {
        paddingRight: 0,
      },
    },
  },
  detail: {
    '& > span': {
      fontSize: '12px',
      lineHeight: '24px',
      display: 'inline-flex',
    },
    marginTop: '8px',
    display: 'flex',
    wordBreak: 'break-word',
  },
  label: {
    color: 'rgba(0,0,0,0.7)',
    textTransform: 'uppercase',
    flex: '0 0 100px',
  },
  value: {
    color: '#000000',
    flex: '1 1',
  },
  header: {
    flex: '1 1',
    borderBottom: '1px solid #DDDDDD',
    color: '#000000',
    fontSize: '16px',
    paddingBottom: '5px',
    lineHeight: '20px',
  },
};

export default withStyles(styles, { name: 'LogDetails' })(LogDetails);
