import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { withStyles } from '@material-ui/core/styles';
import { Translate, translate } from 'react-i18nify';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import GoodIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';

import classNames from 'classnames';

import CircularProgress from '@material-ui/core/CircularProgress';

import UploadModal from '../containers/smb_account_settings/UploadModal';
import SyslogModal from '../containers/smb_account_settings/SyslogModal';
import SyslogRemoveModal from '../containers/smb_account_settings/SyslogRemoveModal';
import RecategorizeDomainModal from '../containers/smb_account_settings/RecategorizeDomainModal';

import SectionHeader from './SectionHeader';
import { LOGO_PATH } from '../lib/Env';
import Paper from './CudaPaper';
import { SettingsIcon } from './icons';

import GridColumn from './common/GridColumn';

import StatusBar from '../containers/smb_account_settings/StatusBar';
import SettingsItem from './settings/SettingsItem';
import Support from './settings/Support';
import NativeSelect from '@material-ui/core/NativeSelect';
import * as Env from '../lib/Env';

class SMBAccountSettings extends React.Component {
  componentDidMount = () => {
    this.props.initData();
  };

  render() {
    const {
      classes,
      msp,
      wca,
      connStatus,
      showUploadModal,
      processing,
      openUploadModal,
      closeUploadModal,
      uploadLogo,
      saveSyslogConfig,
      logo,
      showSyslogModal,
      showSyslogRemoveModal,
      openSyslogModal,
      openSyslogModalForDemoAccounts,
      openSyslogRemoveModal,
      closeSyslogModal,
      closeSyslogRemoveModal,
      fields,
      updateSupportStatus,
      updateSyslogType,
      updateSyslogEncr,
      toggleSupport,
      saving,
      syslogIp,
      syslogExists,
      syslogSyncGood,
      syslogSyncError,
      syslogSyncWarn,
      syslogLastSync,
      saveSyslog,
      removeSyslog,
      features,
      domain,
      category_name,
      getDomainCategory,
      updateDomain,
      domainVal,
      updateRecategorizeDomain,
      categories,
      getDomainCategoryList,
      item,
      value,
      label,
      recategorizeDomainHandleChange,
      selectedOption,
      openRecategorizeModal,
      showRecategorizeDomain,
      closeRecategorizeModal,
      recategorizeDomainConfirm,
      accountId,
    } = this.props;
    const currentLogo = logo || LOGO_PATH;
    let demo_accounts = Env.demo_accounts;
    return (
      <DocumentTitle
        title={translate('components.smbAccountSettings.pageTitle')}
        data-test-id="account-settings-title"
      >
        <React.Fragment>
          <SectionHeader
            icon={<SettingsIcon style={{ color: '#5F5F5F' }} />}
            title={translate('components.smbAccountSettings.title')}
          />
          <Grid
            data-test-id="custom-logo-wrapper"
            spacing={2}
            container
            alignItems="flex-start"
          >
            <Grid item xs={12} md={7} className={classes.logoBoxWrapper}>
              <Paper
                title={translate(
                  'components.smbAccountSettings.customBranding'
                )}
                subTitle={translate(
                  'components.smbAccountSettings.customBrandingSub'
                )}
                loading={processing}
                padding
              >
                <Translate
                  className={classes.label}
                  value="components.smbAccountSettings.currentLogo"
                  tag="div"
                />
                <div className={classNames(classes.logoWrapper, classes.row)}>
                  {!processing && (
                    <img data-test-id="logo" src={currentLogo} alt="logo" />
                  )}
                </div>
                <div className={classNames(classes.row, classes.addRow)}>
                  <span className={classes.label}>
                    <Translate value="components.smbAccountSettings.uploadFromFile" />
                  </span>
                  <Button
                    id="select-logo-file"
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={() => {
                      openUploadModal();
                    }}
                  >
                    {translate('components.smbAccountSettings.selectFile')}
                  </Button>
                </div>
                <div className={classes.subText}>
                  <Translate value="components.smbAccountSettings.maxHeight" />
                </div>
                <div className={classes.subText}>
                  <Translate value="components.smbAccountSettings.maxWidth" />
                </div>
              </Paper>
              <Grid
                className={classes.settingsWrapper}
                spacing={2}
                container
                alignItems="flex-start"
              />
            </Grid>
            <GridColumn xs={12} md={5} spacing={2}>
              {wca ? (
                <Grid item xs={12}>
                  <Paper
                    title={translate(
                      'components.smbAccountSettings.syslogConfigTitle'
                    )}
                    subTitle={translate(
                      'components.smbAccountSettings.syslogConfigHelp'
                    )}
                    loading={processing}
                    padding
                  >
                    {demo_accounts.indexOf(accountId) > -1 ? (
                      <div className={classes.syslogStatus}>
                        {<GoodIcon className={classes.goodIcon} />}
                        {translate(
                          'components.smbAccountSettings.syslogConfigActiveStatus'
                        )}
                        (192.168.1.1)
                      </div>
                    ) : null}

                    {syslogExists &&
                    !(demo_accounts.indexOf(accountId) > -1) ? (
                      <div className={classes.syslogStatus}>
                        {syslogSyncGood ? (
                          <GoodIcon className={classes.goodIcon} />
                        ) : null}
                        {syslogSyncError ? (
                          <ErrorIcon className={classes.failedIcon} />
                        ) : null}
                        {syslogSyncWarn ? (
                          <WarningIcon className={classes.warningIcon} />
                        ) : null}
                        {syslogSyncGood
                          ? translate(
                              'components.smbAccountSettings.syslogConfigActiveStatus'
                            )
                          : null}
                        {syslogSyncError
                          ? translate(
                              'components.smbAccountSettings.syslogConfigFailStatus'
                            )
                          : null}
                        {syslogSyncWarn
                          ? translate(
                              'components.smbAccountSettings.syslogConfigInactiveStatus'
                            )
                          : null}
                        ({syslogIp})
                      </div>
                    ) : null}
                    {syslogExists &&
                    !(demo_accounts.indexOf(accountId) > -1) ? (
                      <div className={classes.syslogSync}>
                        <Translate value="components.smbAccountSettings.syslogConfigSyncText" />
                        {syslogLastSync}
                      </div>
                    ) : null}
                    {!(demo_accounts.indexOf(accountId) > -1) ? (
                      <div className={classes.syslogButton}>
                        {syslogExists ? (
                          <Button
                            id="edit-syslog-server"
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={() => {
                              openSyslogModal();
                            }}
                            loading={processing}
                          >
                            {translate(
                              'components.smbAccountSettings.syslogConfigEdit'
                            )}
                          </Button>
                        ) : (
                          <Button
                            id="configure-syslog-server"
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={() => {
                              openSyslogModal();
                            }}
                            loading={processing}
                          >
                            {translate(
                              'components.smbAccountSettings.syslogConfigCreate'
                            )}
                          </Button>
                        )}
                      </div>
                    ) : (
                      <div className={classes.syslogButton}>
                        <Button
                          id="edit-syslog-server"
                          variant="contained"
                          color="secondary"
                          className={classes.button}
                          onClick={() => {
                            openSyslogModalForDemoAccounts();
                          }}
                          loading={processing}
                        >
                          {translate(
                            'components.smbAccountSettings.syslogConfigEdit'
                          )}
                        </Button>
                      </div>
                    )}
                    {syslogExists &&
                    !(demo_accounts.indexOf(accountId) > -1) ? (
                      <div className={classes.syslogWrapper}>
                        <Button
                          type="clear"
                          id="delete-syslog-server"
                          color="primary"
                          onClick={() => {
                            openSyslogRemoveModal();
                          }}
                          loading={processing}
                        >
                          {translate(
                            'components.smbAccountSettings.syslogConfigRemove'
                          )}
                        </Button>
                      </div>
                    ) : null}
                  </Paper>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Paper
                  title={translate(
                    'components.smbAccountSettings.LookupforDomainCategory'
                  )}
                  loading={processing}
                  padding
                >
                  <div className={classes.domainNameHeader}>
                    {translate('components.smbAccountSettings.domainLookup')}
                  </div>
                  <div className={classes.domainNameWrapper}>
                    <span>
                      <TextField
                        id="domain-name-input"
                        autoFocus
                        disableUnderline
                        style={{
                          backgroundColor: '#FFFFFF',
                        }}
                        value={domain}
                        onChange={updateDomain}
                        onKeyPress={ev => {
                          if (ev.key === 'Enter') {
                            getDomainCategory(domain);
                            ev.preventDefault();
                          }
                        }}
                        placeholder={translate(
                          'components.smbAccountSettings.domainlookupPlaceholder'
                        )}
                      />
                      &nbsp;&nbsp;&nbsp;
                      <Button
                        id="get-domain-category"
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        disabled={!domain}
                        onClick={() => {
                          getDomainCategory(domain);
                        }}
                        loading={processing}
                      >
                        {translate(
                          'components.smbAccountSettings.lookupDomainBtn'
                        )}
                      </Button>
                    </span>
                  </div>
                  <br />
                  <div>
                    <span>
                      <SettingsItem
                        disabled={false}
                        text={
                          <Translate
                            value="components.smbAccountSettings.categoryName"
                            className={classes.label}
                          />
                        }
                        padding={false}
                        customClasses={{
                          labelWrapper: classes.labelWrapper,
                        }}
                      />
                      <div>{category_name}</div>
                    </span>
                  </div>
                </Paper>
              </Grid>
              {wca ? (
                <Grid item xs={12}>
                  <Paper
                    title={translate(
                      'components.smbAccountSettings.recategorizeDomainTitle'
                    )}
                    loading={processing}
                    padding
                  >
                    <br />
                    <div className={classes.domainNameHeader}>
                      {translate('components.smbAccountSettings.domainName')}
                    </div>
                    <div className={classes.domainNameWrapper}>
                      <span>
                        <TextField
                          id="domain-name-input"
                          autoFocus
                          disableUnderline
                          style={{
                            backgroundColor: '#FFFFFF',
                          }}
                          value={domainVal}
                          onChange={updateRecategorizeDomain}
                          onKeyPress={ev => {
                            if (ev.key === 'Enter') {
                              getDomainCategoryList(domainVal);
                              ev.preventDefault();
                            }
                          }}
                          onKeyDown={ev => {
                            if (ev.key === 'Tab') {
                              getDomainCategoryList(domainVal);
                              ev.preventDefault();
                            }
                          }}
                          placeholder={translate(
                            'components.smbAccountSettings.domainlookupPlaceholder'
                          )}
                        />
                      </span>
                    </div>
                    <br />
                    <div>
                      <NativeSelect
                        isDisabled={!domainVal}
                        autosize={true}
                        isClearable={false}
                        className={classes.selectDropdown}
                        options={categories}
                        onChange={event =>
                          recategorizeDomainHandleChange(event.target.value)
                        }
                      >
                        {categories.map(item => (
                          <option value={item}>{item}</option>
                        ))}
                      </NativeSelect>
                      <br />
                      <br />
                      <center>
                        <Button
                          id="recategorize_domain"
                          variant="contained"
                          disabled={!domainVal && !selectedOption}
                          color="secondary"
                          className={classes.button}
                          onClick={openRecategorizeModal}
                          loading={processing}
                        >
                          {translate(
                            'components.smbAccountSettings.recategorizeDomain'
                          )}
                        </Button>
                      </center>
                    </div>
                  </Paper>
                </Grid>
              ) : null}
              {!msp ? (
                <Grid item xs={12}>
                  <Support
                    processing={processing}
                    support_enabled={fields.support_enabled}
                    toggleSupport={toggleSupport}
                    saving={saving}
                  />
                </Grid>
              ) : null}
            </GridColumn>
          </Grid>
          <UploadModal
            show={showUploadModal}
            closeDialog={closeUploadModal}
            uploadLogo={uploadLogo}
            processing={processing}
          />
          <SyslogModal
            show={showSyslogModal}
            closeDialog={closeSyslogModal}
            saveSyslogConfig={saveSyslogConfig}
            processing={processing}
          />
          <SyslogRemoveModal
            show={showSyslogRemoveModal}
            closeDialog={closeSyslogRemoveModal}
            removeSyslog={removeSyslog}
            processing={processing}
          />
          <RecategorizeDomainModal
            show={showRecategorizeDomain}
            closeDialog={closeRecategorizeModal}
            recategorizeDomainConfirm={recategorizeDomainConfirm}
            processing={processing}
          />
          <StatusBar />
        </React.Fragment>
      </DocumentTitle>
    );
  }
}

SMBAccountSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  openUploadModal: PropTypes.func.isRequired,
  uploadLogo: PropTypes.func.isRequired,
  saveSyslogConfig: PropTypes.func.isRequired,
  openSyslogModal: PropTypes.func.isRequired,
  openSyslogModalForDemoAccounts: PropTypes.func.isRequired,
  openSyslogRemoveModal: PropTypes.func.isRequired,
  initData: PropTypes.func.isRequired,
  logo: PropTypes.string.isRequired,
  toggleField: PropTypes.func.isRequired,
  updateAgentPassword: PropTypes.func.isRequired,
  toggleRemoveAgent: PropTypes.func.isRequired,
  msp: PropTypes.bool.isRequired,
  wca: PropTypes.bool.isRequired,
  features: PropTypes.array.isRequired,
  domain: PropTypes.string.isRequired,
  category_name: PropTypes.string.isRequired,
  getDomainCategory: PropTypes.func.isRequired,
  updateDomain: PropTypes.func.isRequired,
  updateRecategorizeDomain: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  getDomainCategoryList: PropTypes.func.isRequired,
  recategorizeDomainHandleChange: PropTypes.func.isRequired,
  openRecategorizeModal: PropTypes.func.isRequired,
  selectedOption: PropTypes.string.isRequired,
  recategorizeDomainConfirm: PropTypes.func.isRequired,
};

const styles = theme => ({
  settingsWrapper: {
    marginTop: '5px !important',
  },
  logoBoxWrapper: {
    marginTop: '-7px !important',
  },
  processExclusionsWrapper: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  processExclusionsHeader: {
    margin: '10px 0px 10px 0px',
    textTransform: 'uppercase',
    color: 'rgba(0,0,0,0.5)',
    fontFamily: 'Roboto',
    fontSize: '14px',
  },
  settingsWrapper: {
    marginTop: '5px !important',
  },
  logoBoxWrapper: {
    marginTop: '-7px !important',
  },
  subText: {
    color: theme.colors.secondaryText,
    fontSize: '12px',
    lineHeight: '20px',
    marginLeft: '150px',
  },
  label: {
    color: theme.colors.primaryText,
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: '4px',
  },
  button: {
    margin: 0,
    padding: '8px 20px',
  },
  row: {
    display: 'flex',
    marginBottom: '16px',
  },
  addRow: {
    alignItems: 'center',
    '& $label': {
      marginBottom: 0,
      flex: '0 0 150px',
    },
  },
  logoWrapper: {
    border: '1px solid #DDDDDD',
    minHeight: '100px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    '& > img': {
      maxWidth: '240px',
      maxHeight: '240px',
    },
  },
  logoRuleText: {
    fontSize: '14px',
    lineHeight: '24px',
    color: theme.colors.primaryText,
  },
  dropzone: {
    marginBottom: '16px',
  },
  syslogWrapper: {
    minHeight: '40px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  syslogButton: {
    minHeight: '60px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  syslogStatus: {
    fontSize: '24px',
    minHeight: '40px',
    justifyContent: 'center',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  syslogSync: {
    fontSize: '16px',
    minHeight: '20px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.secondaryText,
    paddingRight: '10px',
  },
  error: {
    color: theme.statusColors.failure,
  },
  failedIcon: {
    fill: theme.statusColors.failure,
  },
  goodIcon: {
    fill: theme.statusColors.success,
  },
  warningIcon: {
    fill: theme.statusColors.warning,
  },
  modalRow: {
    marginBottom: '16px',
  },
});

export default withStyles(styles, { name: 'SMBAccountSettings' })(
  SMBAccountSettings
);
