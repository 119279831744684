import Auth from '../lib/Auth';
import * as Env from '../lib/Env';
import {
  updateSubscriptions,
  storeSubscriptions,
  storeSerials,
} from './account';
import { storeDecodedSSOToken } from './activate';
import { showSessionModal } from './account';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_MFA_REQUIRED = 'LOGIN_MFA_REQUIRED';
export const LOGIN_RESTRICT = 'LOGIN_RESTRICT';

export const loginFetch = credentials =>
  window.fetch(Env.LOGIN_URL, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({
      username: credentials.username,
      password: credentials.password,
    }),
  });

export const displayError = (errorCode, error) => ({
  type: LOGIN_FAILURE,
  errorCode,
  error,
});

export const activate_login = credentials => dispatch => {
  dispatch({ type: LOGIN_REQUEST });
  return loginFetch(credentials)
    .then(response => {
      return response.json();
    })
    .then(resp => {
      Auth.clear_local_storage();
      const dtoken = Auth.setup_sso(resp['id_token'], resp['refresh_token']);
      dispatch({ type: LOGIN_SUCCESS });
      dispatch(storeDecodedSSOToken(dtoken));
    })
    .catch(error => {
      dispatch({ type: LOGIN_FAILURE, error: error.message });
    });
};

export const demo_login = credentials => dispatch => {
  dispatch({ type: LOGIN_REQUEST });

  return window
    .fetch(Env.DEMO_URL, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({}),
    })
    .then(
      response => {
        return response.json();
      },
      function(error) {
        dispatch({ type: LOGIN_FAILURE, error: error.message });
        throw error;
      }
    )
    .then(resp => {
      if (resp.code === 400) {
        dispatch({ type: LOGIN_MFA_REQUIRED, error: resp.message });
        return;
      }

      Auth.clear_local_storage();

      let host = window.location.host;

      // Only set region if one is returned
      if (resp['region']) {
        Auth.setup_region(resp['region']);
      }

      const dest = Env.BCS_HOST[resp['region']];

      const dtoken = Auth.setup_sso(resp['id_token'], resp['refresh_token']);
      if (
        dest &&
        host.indexOf(dest) === -1 &&
        host.indexOf('localhost') === -1
      ) {
        window.location.href = `http://${dest}`;
        return;
      }

      if (Auth.timer) {
        clearTimeout(Auth.timer);
      }
      setTimeout(() => {
        dispatch(showSessionModal());
      }, (dtoken['exp'] - dtoken['auth_time']) * (3 / 4) * 1000);

      return window.fetch(Env.TOKEN_URL, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: resp['username'],
          token: resp['id_token'],
        }),
      });
    })
    .then(
      response => {
        var success = /20.+/;
        if (success.test(response['status'])) {
          return response.json();
        } else {
          dispatch({
            type: LOGIN_FAILURE,
            error: 'Failed to authenticate',
            errorCode: response['status'],
          });
          throw response;
        }
      },
      function(error) {
        dispatch({ type: LOGIN_FAILURE, error: error.message });
        throw error;
      }
    )
    .then(response => {
      Auth.store_token(response.token);
      dispatch({ type: LOGIN_SUCCESS });
      dispatch(
        updateSubscriptions(response.subscriptions, response.account_subs)
      );
      dispatch(
        storeSubscriptions(response.subscriptions, response.account_subs)
      );
      dispatch(storeSerials(response.serials));
      return response.token;
    });
};

export const login = credentials => dispatch => {
  dispatch({ type: LOGIN_REQUEST });

  return loginFetch(credentials)
    .then(
      response => {
        return response.json();
      },
      function(error) {
        dispatch({ type: LOGIN_FAILURE, error: error.message });
        throw error;
      }
    )
    .then(resp => {
      console.log('the resp: ', resp);
      if (resp.code === 400) {
        dispatch({ type: LOGIN_MFA_REQUIRED, error: resp.message });
        return;
      }

      if (resp.code === 406) {
        dispatch({ type: LOGIN_RESTRICT, error: resp.message });
        return;
      }

      Auth.clear_local_storage();

      let host = window.location.host;

      // Only set region if one is returned
      if (resp['region']) {
        Auth.setup_region(resp['region']);
      }

      const dest = Env.BCS_HOST[resp['region']];

      const dtoken = Auth.setup_sso(resp['id_token'], resp['refresh_token']);
      if (
        dest &&
        host.indexOf(dest) === -1 &&
        host.indexOf('localhost') === -1
      ) {
        window.location.href = `http://${dest}`;
        return;
      }

      if (Auth.timer) {
        clearTimeout(Auth.timer);
      }
      setTimeout(() => {
        dispatch(showSessionModal());
      }, (dtoken['exp'] - dtoken['auth_time']) * (3 / 4) * 1000);

      return window.fetch(Env.TOKEN_URL, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: resp['username'],
          token: resp['id_token'],
        }),
      });
    })
    .then(
      response => {
        var success = /20.+/;
        if (success.test(response['status'])) {
          return response.json();
        } else {
          dispatch({
            type: LOGIN_FAILURE,
            error: 'Failed to authenticate',
            errorCode: response['status'],
          });
          throw response;
        }
      },
      function(error) {
        dispatch({ type: LOGIN_FAILURE, error: error.message });
        throw error;
      }
    )
    .then(response => {
      Auth.store_token(response.token);
      dispatch({ type: LOGIN_SUCCESS });
      dispatch(
        updateSubscriptions(response.subscriptions, response.account_subs)
      );
      dispatch(
        storeSubscriptions(response.subscriptions, response.account_subs)
      );
      dispatch(storeSerials(response.serials));
      return response.token;
    });
};
