import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { withStyles } from '@material-ui/core/styles';
import { Translate, translate } from 'react-i18nify';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import classNames from 'classnames';

import CircularProgress from '@material-ui/core/CircularProgress';

import SectionHeader from './SectionHeader';
import Paper from './CudaPaper';
import { SettingsIcon } from './icons';

import GridColumn from './common/GridColumn';
import Dropdown from './common/Dropdown';

import StatusBar from '../containers/agent_settings/StatusBar';
import SettingsItem from './settings/SettingsItem';
import Support from './settings/Support';

import PasswordInput from './PasswordInput';

import LocalDomains from '../containers/LocalDomains';
import CpuThrottle from '../containers/CpuThrottle';

class AgentSettings extends React.Component {
  componentDidMount = () => {
    this.props.initData();
  };

  render() {
    const {
      classes,
      msp,
      wca,
      processing,
      autoUpdate,
      fields,
      periods,
      passwordShow,
      hidePassword,
      updatePeriod,
      updateTime,
      updateOptions,
      updateAgentUpdate,
      updateAgentPassword,
      updateSupportStatus,
      updateSyslogType,
      updateSyslogEncr,
      toggleField,
      toggleRemoveAgent,
      saving,
    } = this.props;

    return (
      <DocumentTitle
        title={translate('components.agentSettings.title')}
        data-test-id="agent-settings-title"
      >
        <React.Fragment>
          <SectionHeader
            icon={<SettingsIcon style={{ color: '#5F5F5F' }} />}
            title={translate('components.agentSettings.title')}
          />
          <Grid
            data-test-id="custom-logo-wrapper"
            spacing={2}
            container
            alignItems="flex-start"
          >
            <Grid item xs={12} className={classes.logoBoxWrapper}>
              <Grid
                className={classes.settingsWrapper}
                spacing={2}
                container
                alignItems="flex-start"
              >
                <Grid item xs={12}>
                  <Paper
                    title={translate(
                      'components.agentSettings.utilizationTitle'
                    )}
                    subTitle={translate(
                      'components.agentSettings.utilizationSubtitle'
                    )}
                    loading={processing}
                    padding
                  >
                    <SettingsItem
                      text={
                        <Translate
                          value="components.agentSettings.tamperproof"
                          className={classes.label}
                        />
                      }
                      toggled={fields.remove_agent_enabled}
                      onToggle={toggleRemoveAgent}
                      padding={false}
                      disabled={saving}
                      labels={['components.shared.on', 'components.shared.off']}
                    />
                    <Grid
                      container
                      spacing={2}
                      data-test-id="agent-password-wrapper"
                      lg={4}
                    >
                      <Grid item xs={12} md={6} lg={6}>
                        <Translate
                          className={classes.label}
                          value="components.agentSettings.removeAgentPassword"
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <PasswordInput
                          fullWidth={false}
                          passwordShow={passwordShow}
                          saveState={hidePassword}
                          onChange={updateAgentPassword}
                          disabled={!fields.remove_agent_enabled}
                          value={fields.remove_agent_password}
                        />
                      </Grid>
                    </Grid>
                    <CpuThrottle />
                    <div className={classes.titleText}>
                      <Translate
                        className={classes.titleText}
                        value="Agent Update"
                      />
                    </div>
                    <div className={classes.subText}>
                      <Translate
                        className={classes.subText}
                        value="components.agentSettings.updateSubTitle"
                      />
                    </div>
                    <Translate
                      className={classes.label}
                      value="components.agentSettings.applyUpdates"
                      tag="div"
                    />
                    <Grid xs={4}>
                      <Dropdown
                        data-test-id="agent-update-type"
                        value={updateOptions.find(
                          option => option.value === fields.updateSetting
                        )}
                        options={updateOptions}
                        isDisabled={saving}
                        className={classNames(
                          fields.updateSetting === 'time' && classes.updateType
                        )}
                        onChange={updateAgentUpdate}
                      />
                    </Grid>
                    {fields.updateSetting === 'time' && (
                      <React.Fragment>
                        <Translate
                          className={classes.label}
                          value="components.agentSettings.updateAt"
                          tag="div"
                        />
                        <div className={classes.timeWrapper}>
                          <TextField
                            data-test-id="agent-update-hour"
                            value={fields.hour}
                            name="hour"
                            disabled={saving}
                            InputProps={{
                              disableUnderline: true,
                              classes: {
                                root: classNames(
                                  classes.input,
                                  classes.shortInput
                                ),
                              },
                            }}
                            onChange={updateTime}
                          />
                          <span className={classes.colon}>:</span>
                          <TextField
                            data-test-id="agent-update-minute"
                            value={fields.minute}
                            name="minute"
                            disabled={saving}
                            InputProps={{
                              disableUnderline: true,
                              classes: {
                                root: classNames(
                                  classes.input,
                                  classes.shortInput
                                ),
                              },
                            }}
                            onChange={updateTime}
                          />
                          <Dropdown
                            data-test-id="agent-update-period"
                            value={periods.find(
                              option => option.value === fields.period
                            )}
                            options={periods}
                            isDisabled={saving}
                            className={classes.amDropdown}
                            onChange={updatePeriod}
                          />
                          <Translate
                            className={classes.timeText}
                            value="components.agentSettings.localTime"
                          />
                        </div>
                      </React.Fragment>
                    )}
                    <div className={classes.addGap} />
                    <div className={classes.titleText}>
                      <Translate
                        className={classes.titleText}
                        value="components.agentSettings.defineLocalDomainsTitle"
                      />
                    </div>
                    <div className={classes.subText}>
                      <Translate
                        className={classes.subText}
                        value="components.agentSettings.definelLocalDomainsSubTitle"
                      />
                    </div>
                    <div className={classes.localDomainHeader}>
                      <Translate
                        className={classes.label}
                        value="components.agentSettings.localDomains"
                      />
                    </div>
                    <LocalDomains />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <StatusBar />
        </React.Fragment>
      </DocumentTitle>
    );
  }
}

AgentSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  initData: PropTypes.func.isRequired,
  passwordShow: PropTypes.bool.isRequired,
  toggleField: PropTypes.func.isRequired,
  updateAgentPassword: PropTypes.func.isRequired,
  toggleRemoveAgent: PropTypes.func.isRequired,
  msp: PropTypes.bool.isRequired,
  wca: PropTypes.bool.isRequired,
};

const styles = theme => ({
  localDomainHeader: {
    margin: '10px 0px 10px 0px',
    textTransform: 'uppercase',
    color: 'rgba(0,0,0,0.5)',
    fontFamily: 'Roboto',
    fontSize: '14px',
  },
  processExclusionsWrapper: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  addGap: {
    marginTop: '20px',
  },
  processExclusionsHeader: {
    margin: '10px 0px 10px 0px',
    textTransform: 'uppercase',
    color: 'rgba(0,0,0,0.5)',
    fontFamily: 'Roboto',
    fontSize: '14px',
  },
  settingsWrapper: {
    marginTop: '5px !important',
  },
  logoBoxWrapper: {
    marginTop: '-7px !important',
  },
  subText: {
    color: theme.colors.secondaryText,
    fontSize: '12px',
    lineHeight: '14px',
    marginBottom: '24px',
  },
  titleText: {
    fontSize: '24px',
    fontWeight: '10',
    lineHeight: '36px',
  },
  label: {
    color: theme.colors.primaryText,
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: '4px',
  },
  updateType: {
    marginBottom: '16px',
  },
  input: {
    border: '1px solid #DDDDDD',
    boxShadow: '0 1px 0 0 rgba(0,0,0,0.06)',
    borderRadius: '2px',
    paddingLeft: '6px',
    paddingRight: '6px',
    fontSize: '14px',
    '& > input': {
      lineHeight: '20px',
      padding: '5px ',
    },
  },
  shortInput: {
    width: '40px',
  },

  timeText: {
    fontSize: '14px',
  },
  colon: {
    margin: '0 4px',
    lineHeight: '20px',
  },
  amDropdown: {
    margin: '0 16px',
    width: '75px',
  },
  timeWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default withStyles(styles, { name: 'AgentSettings' })(AgentSettings);
