import { connect } from 'react-redux';
import UserAuthentication from '../components/UserAuthentication';
import {
  getSettings,
  closeModal,
  changeType,
} from '../actions/user_authentication';

const mapStateToProps = state => ({
  userType: state.userAuthentication.userType,
  loading: state.userAuthentication.loading,
  synctool: state.userAuthentication.synctool,
  modal: state.userAuthentication.modal,
  configured: state.userAuthentication.directoryConfigured,
});

const mapDispatchToProps = dispatch => ({
  initData: () => dispatch(getSettings()),
  confirmPrompt: () => dispatch(changeType()),
  closePrompt: () => dispatch(closeModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAuthentication);
