import React from 'react';
import PropTypes from 'prop-types';
import { Translate, translate } from 'react-i18nify';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import AsyncSelect from 'react-select/async';
import { components } from 'react-select';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { CudaDialogTitle } from '../CudaDialog';
import CudaMenu from '../CudaMenu';

const customStyles = {
  control: base => ({
    ...base,
    border: 'none',
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    fontSize: '14px',
    color: '#555555',
  }),
  placeholder: base => ({
    ...base,
    color: '#555555',
    opacity: '0.5',
    margin: '0',
    cursor: 'text',
  }),
  option: base => ({
    ...base,
    fontSize: '14px',
    color: '#555555',
  }),
  singleValue: base => ({
    ...base,
    fontSize: '14px',
    color: '#555555',
  }),
  input: base => ({
    ...base,
    margin: '2px 0',
  }),
};

let SelectTimeoutPtr;
const NoOptionsMessage = props => {
  return (
    <div data-test-id="no-option">
      <components.NoOptionsMessage {...props} />
    </div>
  );
};

const ReportModal = ({
  classes,
  title,
  onClose,
  reportOptions,
  timeOptions,
  frequencyOptions,
  userOptions,
  locationOptions,
  reportIndex,
  selectReport,
  timeframeIndex,
  selectTimeframe,
  frequencyIndex,
  selectFrequency,
  recipients,
  onChangeRecipients,
  hourOptions,
  hourIndex,
  selectHour,
  weekOptions,
  weekIndex,
  selectWeek,
  dayOptions,
  dayIndex,
  selectDay,
  onSave,
  timezone,
  userSelectIndex,
  userSelectHandler,
  locationSelectIndex,
  locationSelectHandler,
  ...other
}) => {
  let hourList = [];
  hourOptions.forEach(hour => {
    hourList.push({
      key: hour['key'],
      label: hour['label'] + ' (' + timezone + ')',
    });
  });

  return (
    <Dialog {...other} fullWidth={true} maxWidth="md">
      <CudaDialogTitle>{title}</CudaDialogTitle>
      <DialogContent>
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={3}>
            <Translate value="components.reporting.reportType" />
          </Grid>
          <Grid item xs={9}>
            <CudaMenu
              options={reportOptions}
              onChange={index => {
                selectReport(index, reportOptions);
              }}
              selectedIndex={reportIndex}
            />
          </Grid>
          {reportIndex === 3 && (
            <Grid item xs={3}>
              <Translate value="shared.locations" />
            </Grid>
          )}
          {reportIndex === 3 && (
            <Grid item xs={9}>
              <CudaMenu
                options={locationOptions}
                onChange={locationSelectHandler}
                selectedIndex={locationSelectIndex}
              />
            </Grid>
          )}
          {reportIndex === 5 && (
            <Grid item xs={3}>
              <Translate value="shared.users" />
            </Grid>
          )}
          {reportIndex === 5 && (
            <Grid item xs={9}>
              <div className={classes.border}>
                <AsyncSelect
                  inputId="selection-wrapper-input"
                  isDisabled={false}
                  styles={customStyles}
                  isClearable={false}
                  placeholder=<span data-test-id="filter-search-placeholder">
                    {translate(`shared.usersPlaceholder`)}
                  </span>
                  loadOptions={(input, callback) => {
                    clearTimeout(SelectTimeoutPtr);
                    SelectTimeoutPtr = setTimeout(() => {
                      callback(
                        userOptions
                          .filter(a =>
                            a.label.toLowerCase().includes(input.toLowerCase())
                          )
                          .slice(0, 100)
                      );
                    }, 1000);
                  }}
                  defaultOptions={userOptions.slice(0, 100)}
                  options={
                    userOptions.length > 100
                      ? userOptions.slice(0, 100)
                      : userOptions
                  }
                  className={classes.selectDropdown}
                  value={userSelectIndex}
                  components={{ NoOptionsMessage }}
                  getValue={value => {}}
                  onChange={selectedOptions => {
                    userSelectHandler(selectedOptions);
                  }}
                />
              </div>
            </Grid>
          )}
          <Grid item xs={3}>
            <Translate value="components.reporting.timeFrame" />
          </Grid>
          <Grid item xs={9}>
            <CudaMenu
              options={timeOptions}
              onChange={selectTimeframe}
              selectedIndex={timeframeIndex}
            />
          </Grid>
          <Grid item xs={3}>
            <Translate value="components.reporting.frequency" />
          </Grid>
          <Grid item xs={9} className={classes.frequencyWrapper}>
            <CudaMenu
              options={frequencyOptions}
              onChange={selectFrequency}
              customClasses={[classes.flexMenu]}
              selectedIndex={frequencyIndex}
            />
            {frequencyOptions[frequencyIndex]['key'] === 'daily' ? (
              <CudaMenu
                options={hourList}
                onChange={selectHour}
                customClasses={[classes.flexMenu]}
                selectedIndex={hourIndex}
              />
            ) : null}
            {frequencyOptions[frequencyIndex]['key'] === 'weekly' ||
            frequencyOptions[frequencyIndex]['key'] === 'biweekly' ? (
              <CudaMenu
                options={weekOptions}
                onChange={selectWeek}
                customClasses={[classes.flexMenu]}
                selectedIndex={weekIndex}
              />
            ) : null}
            {frequencyOptions[frequencyIndex]['key'] === 'monthly' ? (
              <CudaMenu
                options={dayOptions}
                onChange={selectDay}
                customClasses={[classes.flexMenu]}
                selectedIndex={dayIndex}
              />
            ) : null}
            {frequencyOptions[frequencyIndex]['key'] === 'weekly' ||
            frequencyOptions[frequencyIndex]['key'] === 'biweekly' ||
            frequencyOptions[frequencyIndex]['key'] === 'monthly' ? (
              <CudaMenu
                options={hourList}
                onChange={selectHour}
                customClasses={[classes.flexMenu]}
                selectedIndex={hourIndex}
              />
            ) : null}
          </Grid>
          <Grid item xs={3}>
            <Translate value="components.reporting.recipients" />
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth={true}
              multiline
              rows="4"
              placeholder={translate(
                'components.notifications.email_placeholder'
              )}
              onChange={event => {
                onChangeRecipients(event.target.value);
              }}
              value={recipients}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div>
          <Button
            color="secondary"
            variant="contained"
            onClick={onClose}
            className={classes.reportBtn}
            disabled={false}
          >
            <Translate value="shared.cancel" />
          </Button>
          {recipients === '' ? (
            <Tooltip title={translate('components.reporting.emailTooltip')}>
              <span>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    onSave();
                    onClose();
                  }}
                  className={classes.reportBtn}
                  disabled={recipients === ''}
                >
                  <Translate value="shared.schedule" />
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                onSave();
                onClose();
              }}
              className={classes.reportBtn}
              disabled={recipients === ''}
            >
              <Translate value="shared.schedule" />
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

const styles = theme => ({
  container: {
    height: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    width: '100%',
    margin: '0',
  },
  reportBtn: {
    height: '32px',
    lineHeight: '1px',
    margin: '0 16px 0 0',
    padding: '8px 21px',
    fontWeight: '200',
  },
  flexMenu: {
    flexGrow: 1,
    display: 'inline-flex',
    '& > ul': {
      width: '100%',
    },
  },
  frequencyWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    '& >div': {
      margin: '0 8px',
    },
    '& >div:first-child': {
      marginLeft: '0',
    },
    '& >div:last-child': {
      marginRight: '0',
    },
  },
  border: {
    borderRadius: 2,
    backgroundColor: '#FFFFFF',
    border: '1px solid #DDDDDD',
  },
});

ReportModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  reportOptions: PropTypes.array.isRequired,
  timeOptions: PropTypes.array.isRequired,
  frequencyOptions: PropTypes.array.isRequired,
  reportIndex: PropTypes.number.isRequired,
  selectReport: PropTypes.func.isRequired,
  timeframeIndex: PropTypes.number.isRequired,
  selectTimeframe: PropTypes.func.isRequired,
  frequencyIndex: PropTypes.number.isRequired,
  selectFrequency: PropTypes.func.isRequired,
  recipients: PropTypes.string.isRequired,
  onChangeRecipients: PropTypes.func.isRequired,
  hourOptions: PropTypes.array.isRequired,
  selectHour: PropTypes.func.isRequired,
  hourIndex: PropTypes.number.isRequired,
  weekOptions: PropTypes.array.isRequired,
  selectWeek: PropTypes.func.isRequired,
  weekIndex: PropTypes.number.isRequired,
  dayOptions: PropTypes.array.isRequired,
  selectDay: PropTypes.func.isRequired,
  dayIndex: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default withStyles(styles)(ReportModal);
