import { createSelector } from 'reselect';

const getChanges = state => state.exemptPolicies.changes;
const getExemptPolicies = state => state.exemptPolicies;

const getChangedFields = createSelector(
  [getChanges],
  changes => Object.keys(changes)
);

export { getChangedFields, getExemptPolicies };
