import React, { Component } from 'react';
import './App.css';
import { Switch, BrowserRouter, Redirect, Route } from 'react-router-dom';
import Main from './Main';
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import Auth from './lib/Auth';
import {
  REACT_APP_ONETRUST_SCRIPT_DOMAIN,
  REACT_APP_ONETRUST_SCRIPT_URL,
} from './lib/Env';
import { Provider } from 'react-redux';
import Authenticator from './containers/Authenticator';
import Theme from './Theme';

import Activate from './containers/Activate';
import Login from './containers/Login';
import Demo from './containers/Demo';
import ViewReport from './containers/ViewReport';
import GenerateReport from './containers/GenerateReport';
import StartTrial from './containers/signup/StartTrial';
import Signup from './containers/signup/Signup';

class App extends Component {
  componentDidMount = () => {
    const oneTrustEnvVarsSet =
      REACT_APP_ONETRUST_SCRIPT_URL.length > 0 &&
      REACT_APP_ONETRUST_SCRIPT_DOMAIN.length > 0;

    console.log('the here: ', oneTrustEnvVarsSet);
    if (oneTrustEnvVarsSet) {
      window.OptanonWrapper = () => {};
      const script = window.document.createElement('script');
      script.src = REACT_APP_ONETRUST_SCRIPT_URL;
      script.type = 'text/javascript';
      script.charset = 'UTF-8';
      script.setAttribute(
        'data-domain-script',
        REACT_APP_ONETRUST_SCRIPT_DOMAIN
      );
      window.document.body.appendChild(script);
    }
  };

  render() {
    return (
      <ThemeProvider theme={Theme}>
        <Provider store={this.props.store}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/generate_report" component={GenerateReport} />
              <Route
                exact
                path="/view_report/:report/:range/:rows/:index/:user"
                component={ViewReport}
              />
              <Route exact path="/login" component={Login} />
              <Route exact path="/demo" component={Demo} />
              <Route exact path="/login/:error" component={Login} />
              <Route
                exact
                path="/trial"
                render={props =>
                  Auth.checkOnce() ? (
                    <StartTrial />
                  ) : (
                    <Redirect
                      to={{
                        pathname: '/authenticator',
                        state: { from: props.location },
                      }}
                    />
                  )
                }
              />
              <Route path="/signup" component={Signup} />
              <Route exact path="/authenticator" component={Authenticator} />
              <Route
                exact
                path="/activate"
                render={props =>
                  Auth.checkOnce() ? (
                    <Activate />
                  ) : (
                    <Redirect
                      to={{
                        pathname: '/authenticator',
                        state: { from: props.location },
                      }}
                    />
                  )
                }
              />
              <PrivateRoute component={Main} />
            </Switch>
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    );
  }
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      Auth.isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/authenticator',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default App;
