import React, { Component } from 'react';
import { Cookies } from 'react-cookie';
import Auth from './lib/Auth';
import * as Env from './lib/Env';

import WebStorage from './lib/WebStorage';

class Authenticator extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  isMSP = dtoken => {
    const re = /Partner\s*$/g;
    let msp = dtoken['accounts'].some(a => a.type && a.type.match(re));
    if (dtoken['services'].length > 1) {
      let countBcs = 0;
      for (let i = 0; i < dtoken['services'].length; i++) {
        if (
          dtoken['services'][i] &&
          dtoken['services'][i]['service_id'] === 17
        ) {
          countBcs += 1;
          if (countBcs > 1) {
            msp = true;
            break;
          }
        }
      }
    }
    return msp;
  };

  handleSubscription = (subscriptions, account_subs) => {
    this.props.updateSubscriptions(subscriptions || []);
    this.props.storeSubscriptions(subscriptions || [], account_subs || []);
  };

  storeAuthentication = (dtoken, serials = {}, provisioned_accounts = []) => {
    this.props.storeDecodedSSOToken(dtoken, provisioned_accounts); // Store for activation
    this.props.storeUserName(dtoken.name);
    this.props.retrieveAccount(
      dtoken.email,
      dtoken.account,
      dtoken.accounts,
      dtoken.services,
      dtoken.sub,
      dtoken.zoneinfo || dtoken.timezone
    );
    this.props.storeSerials(serials);
  };

  failedRedirect = () => {
    let from = {};

    if (this.props.location.state) {
      from = this.props.location.state.from;
    }

    if (
      from.pathname.indexOf('/activate') === 0 ||
      from.pathname.indexOf('/trial') === 0
    ) {
      this.props.history.push(from);
    } else {
      this.props.history.push('/login');
    }
  };

  successRedirect = smb => {
    let from = { pathname: '/' };

    if (this.props.location.state) {
      from = this.props.location.state.from;
    }

    if (
      from.pathname === '/' ||
      (from.pathname === '/activate' && from.pathname === '/trial')
    ) {
      if (smb) {
        this.props.history.push('/dashboard');
      } else {
        this.props.history.push('/accounts');
      }
    } else if (
      smb &&
      (from.pathname === '/accounts' ||
        from.pathname === '/msp_dashboard' ||
        from.pathname === '/audit_log')
    ) {
      // Make sure SMB lands on dashboard page
      this.props.history.push('/dashboard');
    } else {
      this.props.history.push(from);
    }
  };

  handleRedirect = (success = true, smb = false) => {
    let from = { pathname: '/' };

    if (this.props.location.state) {
      from = this.props.location.state.from;
    }

    if (success) {
      return this.successRedirect(smb);
    }

    this.failedRedirect();
  };

  handleEmptyRedirect = () => {
    let from = { pathname: '/' };

    if (this.props.location.state) {
      from = this.props.location.state.from;
    }

    const sso = WebStorage.get(Env.SSO_SESSION_STORE);

    if (
      from.pathname.indexOf('/activate') === 0 ||
      from.pathname.indexOf('/trial') === 0
    ) {
      // TODO: Need to handle cookie case
      // if SSO token exists
      try {
        if (sso) {
          const dtoken = Auth.store_sso(sso);
          this.props.storeDecodedSSOToken(dtoken);
        }
      } catch (e) {}

      this.props.history.push(from);
    } else {
      this.props.history.push('/login');
    }
  };

  handleSSORefresh = () => {
    const { handleRedirect } = this;
    const { errorMessage, showSessionModal } = this.props;

    const jwe = WebStorage.get(Env.JWE_SESSION_STORE);
    const sso = WebStorage.get(Env.SSO_SESSION_STORE);
    const refresh = Auth.get_refresh_token();

    // CLOUD_AT for support has already been checked.
    // Make sure we are in the right region. In case user
    // enter in wrong URL on refresh.
    let host = window.location.host;
    const region = Auth.get_region();
    const dest = Env.BCS_HOST[region];
    if (
      ((jwe && sso) || refresh) &&
      region &&
      host.indexOf(dest) === -1 &&
      host.indexOf('localhost') === -1
    ) {
      window.location.href = `http://${dest}`;
      return;
    }

    // Correct region and have credentials then follow the same auth
    if (jwe && sso) {
      window
        .fetch(Env.TOKEN_VALIDATOR_URL, {
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify({
            sso,
            token: jwe,
          }),
        })
        .then(response => {
          if (response.status !== 200) {
            throw new Error('Failed to validated token');
          }

          return response.json();
        })
        .then(response => {
          this.handleSubscription(
            response.subscriptions,
            response.account_subs
          );

          const dtoken = Auth.setup_sso(sso, refresh);
          if (Auth.timer) {
            clearTimeout(Auth.timer);
          }
          setTimeout(() => {
            showSessionModal();
          }, (dtoken['exp'] - dtoken['auth_time']) * (3 / 4) * 1000);
          Auth.store_token(jwe);

          return Auth.refresh_token();
        })
        .then(
          response => {
            const account = WebStorage.get(Env.ACCOUNT);
            const name = WebStorage.get(Env.ACCOUNT_NAME);

            const dtoken = Auth.get_sso_token();
            let msp = this.isMSP(dtoken);
            this.storeAuthentication(
              dtoken,
              response.serials,
              response.provisioned_accounts
            );
            this.handleRedirect(true, !msp);
          },
          () => {
            Auth.logout();
            //  handleRedirect(false);
          }
        );
    } else {
      // Check if there's a refresh token to use for
      // authenticating on login and being redirected.
      if (refresh) {
        window
          .fetch(Env.TOKEN_URL, {
            method: 'PUT',
            mode: 'cors',
            body: JSON.stringify({
              refresh_token: refresh,
            }),
          })
          .then(response => {
            if (response.status !== 200) {
              throw new Error('Failed to validated token');
            }

            return response.json();
          })
          .then(
            response => {
              Auth.setup_sso(
                response['sso']['id_token'],
                response['sso']['refresh_token']
              );
              Auth.store_token(response['jwe']);
              const dtoken = Auth.get_sso_token();
              this.handleSubscription(
                response.subscriptions,
                response.account_subs
              );
              let msp = this.isMSP(dtoken);
              this.storeAuthentication(
                dtoken,
                response.serials,
                response.provisioned_accounts
              );
              this.handleRedirect(true, !msp);
            },
            () => {
              Auth.logout();
              handleRedirect(false);
            }
          );
        return;
      }
      Auth.logout();
      this.handleEmptyRedirect();
    }
  };

  componentDidMount() {
    const { handleRedirect } = this;
    const { errorMessage } = this.props;

    const jwe = WebStorage.get(Env.JWE_SESSION_STORE);
    const sso = WebStorage.get(Env.SSO_SESSION_STORE);
    const refreshToken = WebStorage.get(Env.SSO_REFRESH_TOKEN);

    window
      .fetch(Env.COOKIE_VALIDATOR_URL, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify({}),
      })
      .then(response => {
        return response.json();
      })
      .then(response => {
        // Checked for cookies but nothing was sent.
        if (response.statusCode === 200 && response.empty) {
          this.handleSSORefresh();
          return;
        }

        if (response.statusCode !== 200) {
          const error = JSON.parse(response.body);
          errorMessage(error.code, error.error);
          handleRedirect(false);
          return;
        }

        const account = WebStorage.get(Env.ACCOUNT);
        const name = WebStorage.get(Env.ACCOUNT_NAME);

        const dtoken = response.payload;
        this.handleSubscription(dtoken.subscriptions, dtoken.account_subs);
        // Set up token for Support
        Auth.setup_support(dtoken);
        Auth.store_token(response['token']);

        // Make sure we are in the right region. In case user
        // enter in wrong URL on refresh.
        const region = response['region'];
        if (region) {
          Auth.setup_region(region);
        }
        let host = window.location.host;
        const dest = Env.BCS_HOST[region];
        if (
          region &&
          host.indexOf(dest) === -1 &&
          host.indexOf('localhost') === -1
        ) {
          window.location.href = `http://${dest}`;
          return;
        }

        this.storeAuthentication(
          dtoken,
          response.serials,
          response.provisioned_accounts
        );
        // Force SMB for Support login
        let msp = this.isMSP(dtoken);
        this.handleRedirect(true, !msp);
      })
      .catch(error => {
        // For localhost development. If it errors out without a valid http status code.
        // most likely api being rejected by the server so just fall back to handling regular sso.
        this.handleSSORefresh();
      });
  }

  render() {
    return <div />;
  }
}

export default Authenticator;
