import { createSelector } from 'reselect';
import { translate } from 'react-i18nify';

const getCategories = state => state.basic_filtering.catMapping.categories;
const getSupercategories = state =>
  state.basic_filtering.catMapping.supercategories;

const getCategoryList = createSelector(
  [getCategories, getSupercategories],
  (categories, supercategories) => {
    const superCatArr = [];
    let options = [
      {
        key: 0,
        label: translate('components.notifications.anyCategory'),
      },
    ];
    const parsedSuperCats = {};

    Object.keys(categories).forEach(key => {
      if (
        !Object.prototype.hasOwnProperty.call(
          parsedSuperCats,
          categories[key].parent
        )
      ) {
        parsedSuperCats[categories[key].parent] = [];
      }
      parsedSuperCats[categories[key].parent].push({
        key: parseInt(key, 10),
        label: categories[key].display,
      });
    });

    Object.keys(supercategories).forEach(superCat => {
      superCatArr.push({
        key: parseInt(superCat, 10),
        label: supercategories[superCat],
      });
    });
    superCatArr.sort((a, b) => (a.label > b.label) - (a.label < b.label));

    superCatArr.forEach(superCat => {
      options = [
        ...options,
        {
          key: superCat.key,
          label: superCat.label,
          type: 'label',
        },
        ...parsedSuperCats[superCat.key].sort(
          (a, b) => (a.label > b.label) - (a.label < b.label)
        ),
      ];
    });

    return options;
  }
);

const getOptions = state => state.notifications.typeOptions;

const getOptionsHash = createSelector(
  [getOptions],
  options => {
    const response = {};

    options.forEach((val, index) => {
      if (val.type !== 'label') {
        response[val.key] = {
          index,
          label: val.label,
        };
      }
    });

    return response;
  }
);

const getSubscriptions = state => state.account.subscriptions;

const getFilteredOptions = createSelector(
  [getOptions, getSubscriptions],
  (options, subscriptions) => {
    const groupedOptions = [];

    options.forEach(option => {
      if (option.type === 'label') {
        groupedOptions[groupedOptions.length] = [option];
      } else if (option.subscriptions.some(sub => subscriptions[sub])) {
        groupedOptions[groupedOptions.length - 1].push(option);
      }
    });

    return [].concat(...groupedOptions.filter(group => group.length > 1));
  }
);

export { getOptionsHash, getCategoryList, getFilteredOptions };
