import { connect } from 'react-redux';
import AgentSettings from '../components/AgentSettings';

import { getAccountInfo, updateAccountOption } from '../actions/agent_settings';

const mapStateToProps = state => ({
  msp: state.account.msp,
  passwordShow: false,
  processing: state.agentSettings.processing,
  saving: state.agentSettings.saving,
  wca: state.account.subscriptions.wca,
  passwordShow: state.agentSettings.changes.hasOwnProperty(
    'remove_agent_password'
  ),
  hidePassword: state.agentSettings.hidePassword,
  fields: {
    ...state.agentSettings.fields,
    ...state.agentSettings.changes,
  },
  periods: state.agentSettings.periods,
  updateOptions: state.agentSettings.updateOptions,
});

const mapDispatchToProps = dispatch => ({
  initData: () => {
    dispatch(getAccountInfo());
  },
  updatePeriod: period => {
    dispatch(
      updateAccountOption({
        period: period.value,
      })
    );
  },
  updateTime: time => {
    dispatch(
      updateAccountOption({
        [time.target.name]: time.target.value,
      })
    );
  },
  updateAgentUpdate: option => {
    dispatch(
      updateAccountOption({
        updateSetting: option.value,
      })
    );
  },
  toggleField: (field, value) => {
    dispatch(
      updateAccountOption({
        [field]: value,
      })
    );
  },
  toggleRemoveAgent: field => {
    dispatch(
      updateAccountOption({
        remove_agent_enabled: field.target.checked,
      })
    );
  },
  updateAgentPassword: field => {
    dispatch(
      updateAccountOption({
        remove_agent_password: field.target.value,
      })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgentSettings);
