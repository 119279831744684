export const USER_AUTH_TOGGLE_GROUP_EXPAND = 'USER_AUTH_TOGGLE_GROUP_EXPAND';
export const toggleGroupExpand = id => ({
  type: USER_AUTH_TOGGLE_GROUP_EXPAND,
  id,
});

export const USER_AUTH_CHANGE_SORT = 'USER_AUTH_CHANGE_SORT';
export const handleSort = field => ({
  type: USER_AUTH_CHANGE_SORT,
  field,
});

export const USER_AUTH_UPDATE_QUERY = 'USER_AUTH_UPDATE_QUERY';
export const updateQuery = query => ({
  type: USER_AUTH_UPDATE_QUERY,
  query,
});

export const USER_AUTH_OPEN_MODAL = 'USER_AUTH_OPEN_MODAL';
export const openModal = (modal, data) => ({
  type: USER_AUTH_OPEN_MODAL,
  modal,
  data,
});

export const USER_AUTH_CLOSE_MODAL = 'USER_AUTH_CLOSE_MODAL';
export const closeModal = () => ({
  type: USER_AUTH_CLOSE_MODAL,
});

export const USER_AUTH_UPDATE_MODAL_DATA = 'USER_AUTH_UPDATE_MODAL_DATA';
export const updateModalData = (field, value) => ({
  type: USER_AUTH_UPDATE_MODAL_DATA,
  field,
  value,
});

/* sync tool */
export const USER_AUTH_GET_SYNC_TOOL = 'USER_AUTH_GET_SYNC_TOOL';
export const getSyncTool = () => ({
  type: USER_AUTH_GET_SYNC_TOOL,
});
export const USER_AUTH_GET_SYNC_TOOL_SUCCESS =
  'USER_AUTH_GET_SYNC_TOOL_SUCCESS';
export const getSyncToolSuccess = synctool => ({
  type: USER_AUTH_GET_SYNC_TOOL_SUCCESS,
  synctool,
});
export const USER_AUTH_GET_SYNC_TOOL_FAILURE =
  'USER_AUTH_GET_SYNC_TOOL_FAILURE';
export const getSyncToolFailure = () => ({
  type: USER_AUTH_GET_SYNC_TOOL_FAILURE,
});

/* add users */
export const USER_AUTH_ADD_USERS = 'USER_AUTH_ADD_USERS';
export const addUsers = () => ({
  type: USER_AUTH_ADD_USERS,
});
export const USER_AUTH_ADD_USERS_SUCCESS = 'USER_AUTH_ADD_USERS_SUCCESS';
export const addUsersSuccess = (group, users) => ({
  type: USER_AUTH_ADD_USERS_SUCCESS,
  group,
  users,
});
export const USER_AUTH_CREATE_GROUP_SUCCESS = 'USER_AUTH_CREATE_GROUP_SUCCESS';
export const createGroupSuccess = group => ({
  type: USER_AUTH_CREATE_GROUP_SUCCESS,
  group,
});
export const USER_AUTH_ADD_USERS_FAILURE = 'USER_AUTH_ADD_USERS_FAILURE';
export const addUsersFailure = error => ({
  type: USER_AUTH_ADD_USERS_FAILURE,
  error,
});
/* end add users */

/* update user */
export const USER_AUTH_UPDATE_USER = 'USER_AUTH_UPDATE_USER';
export const updateUser = () => ({
  type: USER_AUTH_UPDATE_USER,
});
export const USER_AUTH_UPDATE_USER_SUCCESS = 'USER_AUTH_UPDATE_USER_SUCCESS';
export const updateUserSuccess = user => ({
  type: USER_AUTH_UPDATE_USER_SUCCESS,
  user,
});
export const USER_AUTH_UPDATE_USER_FAILURE = 'USER_AUTH_UPDATE_USER_FAILURE';
export const updateUserFailure = error => ({
  type: USER_AUTH_UPDATE_USER_FAILURE,
  error,
});
/* end update user */

/* move user */
export const USER_AUTH_MOVE_USER = 'USER_AUTH_MOVE_USER';
export const moveUser = () => ({
  type: USER_AUTH_MOVE_USER,
});
export const USER_AUTH_MOVE_USER_SUCCESS = 'USER_AUTH_MOVE_USER_SUCCESS';
export const moveUserSuccess = (user, oldGroup, newGroup) => ({
  type: USER_AUTH_MOVE_USER_SUCCESS,
  user,
  oldGroup,
  newGroup,
});
export const USER_AUTH_MOVE_USER_FAILURE = 'USER_AUTH_MOVE_USER_FAILURE';
export const moveUserFailure = error => ({
  type: USER_AUTH_MOVE_USER_FAILURE,
  error,
});
/* end move user */

/* update group */
export const USER_AUTH_UPDATE_GROUP = 'USER_AUTH_UPDATE_GROUP';
export const updateGroup = () => ({
  type: USER_AUTH_UPDATE_GROUP,
});
export const USER_AUTH_UPDATE_GROUP_SUCCESS = 'USER_AUTH_UPDATE_GROUP_SUCCESS';
export const updateGroupSuccess = group => ({
  type: USER_AUTH_UPDATE_GROUP_SUCCESS,
  group,
});
export const USER_AUTH_UPDATE_GROUP_FAILURE = 'USER_AUTH_UPDATE_GROUP_FAILURE';
export const updateGroupFailure = error => ({
  type: USER_AUTH_UPDATE_GROUP_FAILURE,
  error,
});
/* end update group */

/* remove from group */
export const USER_AUTH_REMOVE_FROM_GROUP = 'USER_AUTH_REMOVE_FROM_GROUP';
export const removeFromGroup = () => ({
  type: USER_AUTH_REMOVE_FROM_GROUP,
});
export const USER_AUTH_REMOVE_FROM_GROUP_SUCCESS =
  'USER_AUTH_REMOVE_FROM_GROUP_SUCCESS';
export const removeFromGroupSuccess = (user, group) => ({
  type: USER_AUTH_REMOVE_FROM_GROUP_SUCCESS,
  user,
  group,
});
export const USER_AUTH_REMOVE_FROM_GROUP_FAILURE =
  'USER_AUTH_REMOVE_FROM_GROUP_FAILURE';
export const removeFromGroupFailure = error => ({
  type: USER_AUTH_REMOVE_FROM_GROUP_FAILURE,
  error,
});
/* end remove from group */

/* delete group */
export const USER_AUTH_DELETE_GROUP = 'USER_AUTH_DELETE_GROUP';
export const deleteGroup = () => ({
  type: USER_AUTH_DELETE_GROUP,
});
export const USER_AUTH_DELETE_GROUP_SUCCESS = 'USER_AUTH_DELETE_GROUP_SUCCESS';
export const deleteGroupSuccess = group => ({
  type: USER_AUTH_DELETE_GROUP_SUCCESS,
  group,
});
export const USER_AUTH_DELETE_GROUP_FAILURE = 'USER_AUTH_DELETE_GROUP_FAILURE';
export const deleteGroupFailure = error => ({
  type: USER_AUTH_DELETE_GROUP_FAILURE,
  error,
});
/* end delete group */

/* load users */
export const USER_AUTH_LOAD_USERS = 'USER_AUTH_LOAD_USERS';
export const loadUsers = () => ({
  type: USER_AUTH_LOAD_USERS,
});

export const USER_AUTH_LOAD_USERS_SUCCESS = 'USER_AUTH_LOAD_USERS_SUCCESS';
export const loadUsersSuccess = (groups, users, userData) => ({
  type: USER_AUTH_LOAD_USERS_SUCCESS,
  groups,
  users,
  userData,
});
export const USER_AUTH_LOAD_USERS_FAILURE = 'USER_AUTH_LOAD_USERS_FAILURE';
export const loadUsersFailure = error => ({
  type: USER_AUTH_LOAD_USERS_FAILURE,
  error,
});
/* end load users */

/* user auth settings */
export const USER_AUTH_GET_SETTINGS = 'USER_AUTH_GET_SETTINGS';
export const getSettings = () => ({
  type: USER_AUTH_GET_SETTINGS,
});

export const USER_AUTH_GET_SETTINGS_SUCCESS = 'USER_AUTH_GET_SETTINGS_SUCCESS';
export const getSettingsSuccess = settings => ({
  type: USER_AUTH_GET_SETTINGS_SUCCESS,
  settings,
});
export const USER_AUTH_GET_SETTINGS_FAILURE = 'USER_AUTH_GET_SETTINGS_FAILURE';
export const getSettingsFailure = error => ({
  type: USER_AUTH_GET_SETTINGS_FAILURE,
  error,
});
/* end user auth settings */

/* user type */
export const USER_AUTH_CHANGE_TYPE = 'USER_AUTH_CHANGE_TYPE';
export const changeType = () => ({
  type: USER_AUTH_CHANGE_TYPE,
});
export const USER_AUTH_CHANGE_TYPE_SUCCESS = 'USER_AUTH_CHANGE_TYPE_SUCCESS';
export const changeTypeSuccess = userType => ({
  type: USER_AUTH_CHANGE_TYPE_SUCCESS,
  userType,
});
export const USER_AUTH_CHANGE_TYPE_FAILURE = 'USER_AUTH_CHANGE_TYPE_FAILURE';
export const changeTypeFailure = () => ({
  type: USER_AUTH_CHANGE_TYPE_FAILURE,
});
/* end user type */

export const USER_AUTH_HIDE_MODAL = 'USER_AUTH_HIDE_MODAL';
export const hideModal = () => ({
  type: USER_AUTH_HIDE_MODAL,
});
