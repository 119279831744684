import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { translate, Translate } from 'react-i18nify';

import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';

import LaunchIcon from '@material-ui/icons/Launch';
import WarningIcon from '@material-ui/icons/Warning';
import GoodIcon from '@material-ui/icons/CheckCircle';

import Paper from '../CudaPaper';
import { AD_CAMPUS_URL } from '../../lib/Env';

import { Mixpanel } from '../../lib/Mixpanel';

const DirectoryServices = ({ classes, configured, loading }) => (
  <Paper
    title={translate('userAuthentication.directory.title')}
    padding
    titleClasses={[classes.title]}
    className={classes.paper}
    loading={loading}
  >
    <Translate value="userAuthentication.directory.subTitle" />
    <List component="ol" disablePadding className={classes.list}>
      {['stepIP', 'stepPort', 'stepBaseDN', 'stepBindDN', 'stepPassword'].map(
        (lid, i) => (
          <li key={lid}>
            <span className={classes.stepNumber}>{`${i + 1}.`}</span>
            <Translate value={`userAuthentication.directory.${lid}`} />
          </li>
        )
      )}
    </List>
    <span className={classes.configure}>
      <Button
        variant="text"
        color="primary"
        className={classes.configureBtn}
        onClick={() => {
          Mixpanel.track(
            'Users / Directory Services-Configure Directory Service',
            {}
          );
          window.open(AD_CAMPUS_URL, '_blank');
        }}
      >
        {translate('userAuthentication.directory.configure')}
        <LaunchIcon />
      </Button>
      {configured ? (
        <React.Fragment>
          <GoodIcon className={classes.successIcon} />
          <Translate value="userAuthentication.directory.configured" />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <WarningIcon className={classes.warningIcon} />
          <Translate value="userAuthentication.directory.notConfigured" />
        </React.Fragment>
      )}
    </span>
  </Paper>
);

const styles = theme => ({
  title: {
    marginBottom: '24px',
  },
  paper: {
    fontSize: '14px',
    lineHeight: '24px',
    color: theme.colors.primaryText,
  },
  configure: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: '8px',
    },
  },
  list: {
    marginBottom: '16px',
  },
  stepNumber: {
    display: 'inline-block',
    width: '25px',
  },
  warningIcon: {
    color: theme.statusColors.warning,
  },
  successIcon: {
    color: theme.statusColors.success,
  },
  configureBtn: {
    paddingLeft: 0,
    paddingRight: 0,
    marginRight: '50px',
    '& svg': {
      height: '18px',
      width: '18px',
      marginLeft: '6px',
    },
  },
});

export default withStyles(styles, { name: 'DirectoryServices' })(
  DirectoryServices
);
