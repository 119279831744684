import { connect } from 'react-redux';
import Demo from '../components/Demo';
import { withRouter } from 'react-router';
import { demo_login } from '../actions/login';

import { BCS_HOST } from '../lib/Env';

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  initData: history => {
    if (BCS_HOST['us-east-1'] === 'bcs.barracudanetworks.com') {
      window.location.href = 'https://auth.barracudanetworks.com/login/demo';
    } else {
      return dispatch(demo_login()).then(
        response => {
          history.push('/route_view');
        },
        error => {}
      );
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Demo));
