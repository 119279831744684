import React from 'react';
import Select from 'react-select';
import { withRouter } from 'react-router';
import { Translate, translate } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import LocationCity from '@material-ui/icons/LocationCity';

import FormField from './FormField';
import Box from './Box';
import ActionButton from './ActionButton';
import ErrorMessage from './ErrorMessage';

import { countriesCodes } from '../../countries';
import { regions } from '../../lib/Env';

import {
  EmailIcon,
  FormUserIcon,
  CompanyIcon,
  PhoneIcon,
  LocationIcon,
  DarkLockIcon,
} from '../icons';

const fillDetailsStyles = {
  formContainer: {
    maxWidth: '856px',
  },
  textContainer: {
    paddingTop: '12px',
    textAlign: 'left',
  },
  iconDiv: {
    marginBottom: '5px',
  },
  title: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: '500',
    marginBottom: '20px',
    textAlign: 'center',
  },
  text: {
    fontSize: '14px',
    lineHeight: '24px',
    marginBottom: '20px',
  },
  formDiv: {
    padding: 8,
    width: '400px',
    height: '100%',
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
  inputDiv: {
    margin: '5px 0',
  },
  input: {
    width: '100%',
  },
  actionButton: {
    width: '100%',
    margin: 0,
  },
  countryWrapper: {
    display: 'flex',
    paddingBottom: '16px',
    alignItems: 'center',
    '& > svg': {
      marginRight: '10px',
    },
  },
  regionWrapper: {
    paddingTop: '0px !important',
  },
  regionHelper: {
    color: 'rgba(0,0,0,0.7)',
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'left',
    paddingLeft: '31px',
  },
};

const customStyles = {
  control: base => ({
    ...base,
    border: '1px solid #DDDDDD',
    borderRadius: '2px',
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    fontSize: '14px',
    color: '#555555',
    minHeight: 32,
    height: 32,
  }),
  container: base => ({
    ...base,
    height: 32,
    width: '100%',
  }),
  option: base => ({
    ...base,
    fontSize: '14px',
    color: '#555555',
  }),
  singleValue: base => ({
    ...base,
    fontSize: '14px',
    color: '#555555',
  }),
  input: base => ({
    ...base,
    margin: '2px 0',
  }),
};

class FillDetails extends React.Component {
  state = {
    email: '',
  };

  componentDidMount() {
    const { location, updateFields } = this.props;
    let token;
    let email;

    if (location.search) {
      let matches = location.search.match(/email=([^&]*)/) || [];
      email = matches.length > 1 ? matches[1] : '';
      matches = location.search.match(/token=([^&]*)/) || [];
      token = matches.length > 1 ? matches[1] : '';
      this.setState({ email });
      updateFields({ email, token });
    }
  }

  render() {
    const {
      classes,
      form,
      handleSubmit,
      updateField,
      validateField,
    } = this.props;
    const { email } = this.state;

    return (
      <Box
        title={translate('signup.fillDetails.title')}
        className={classes.formContainer}
      >
        <Grid item container>
          <Grid
            item
            container
            justify="center"
            xs={6}
            className={classes.textContainer}
          >
            <Grid item xs={9} className={classes.text}>
              <Translate value="signup.fillDetails.textP1" />
            </Grid>
            <Grid item xs={9} className={classes.text}>
              <Translate value="signup.fillDetails.textP2" />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <form onSubmit={handleSubmit} style={{ height: '100%' }}>
              <Grid
                item
                container
                direction="column"
                justify="space-evenly"
                className={classes.formDiv}
              >
                {/* Email */}
                <Grid
                  item
                  container
                  justify="flex-start"
                  alignItems="center"
                  spacing={16}
                >
                  <Grid item xs={11}>
                    <FormField
                      name="email"
                      disabled={email}
                      data-test-id="signup-email"
                      placeholder={translate('signup.fillDetails.email')}
                      field={{
                        value: form.fields.email,
                        error: form.errors.email,
                      }}
                      onChange={updateField}
                      onBlur={validateField}
                      icon=<EmailIcon />
                    />
                  </Grid>
                </Grid>
                {/* Region */}
                <Grid
                  item
                  container
                  justify="flex-start"
                  alignItems="center"
                  spacing={16}
                >
                  <Grid item xs={11} className={classes.regionWrapper}>
                    <div className={classes.regionHelper}>
                      {translate('signup.fillDetails.regionHelp')}
                    </div>
                    <div
                      className={classes.countryWrapper}
                      data-test-id="signup-region"
                    >
                      <LocationCity />
                      <Select
                        styles={customStyles}
                        name="signup-region"
                        placeholder={translate('signup.fillDetails.region')}
                        options={regions}
                        className={classes.selectDropdown}
                        value={regions.find(
                          region => region.value === form.fields.region
                        )}
                        onChange={selectedOption => {
                          updateField({
                            target: {
                              name: 'region',
                              value: selectedOption.value || 'US',
                            },
                          });
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
                {/* First and Last name */}
                <Grid
                  item
                  container
                  justify="flex-start"
                  alignItems="center"
                  spacing={16}
                >
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <div className={classes.inputWrapper}>
                        <FormField
                          data-test-id="signup-first-name"
                          name="first_name"
                          placeholder={translate(
                            'signup.fillDetails.firstName'
                          )}
                          field={{
                            value: form.fields.first_name,
                            error: form.errors.first_name,
                          }}
                          onChange={updateField}
                          onBlur={validateField}
                          icon=<FormUserIcon />
                        />
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl fullWidth>
                      <div className={classes.inputWrapper}>
                        <FormField
                          name="last_name"
                          data-test-id="signup-last-name"
                          placeholder={translate('signup.fillDetails.lastName')}
                          field={{
                            value: form.fields.last_name,
                            error: form.errors.last_name,
                          }}
                          onChange={updateField}
                          onBlur={validateField}
                          padding={false}
                        />
                      </div>
                    </FormControl>
                  </Grid>
                </Grid>
                {/* Company name */}
                <Grid
                  item
                  container
                  justify="flex-start"
                  alignItems="center"
                  spacing={16}
                >
                  <Grid item xs={11}>
                    <FormControl fullWidth>
                      <div className={classes.inputWrapper}>
                        <FormField
                          name="company_name"
                          data-test-id="signup-company-name"
                          placeholder={translate(
                            'signup.fillDetails.companyName'
                          )}
                          field={{
                            value: form.fields.company_name,
                            error: form.errors.company_name,
                          }}
                          onChange={updateField}
                          onBlur={validateField}
                          icon=<CompanyIcon />
                        />
                      </div>
                    </FormControl>
                  </Grid>
                </Grid>
                {/* phone number */}
                <Grid
                  item
                  type="text"
                  container
                  justify="flex-start"
                  alignItems="center"
                  spacing={16}
                >
                  <Grid item xs={11}>
                    <FormControl fullWidth>
                      <div className={classes.inputWrapper}>
                        <FormField
                          data-test-id="signup-phone-number"
                          name="phone_number"
                          placeholder={translate(
                            'eg: 123-456-7890 or only digits'
                          )}
                          field={{
                            value: form.fields.phone_number,
                            error: form.errors.phone_number,
                          }}
                          onChange={updateField}
                          onBlur={validateField}
                          icon=<PhoneIcon />
                        />
                      </div>
                    </FormControl>
                  </Grid>
                </Grid>
                {/* country */}
                <Grid
                  item
                  container
                  justify="flex-start"
                  alignItems="center"
                  spacing={16}
                >
                  <Grid item xs={7}>
                    <div
                      className={classes.countryWrapper}
                      data-test-id="signup-country-wrapper"
                    >
                      <LocationIcon />
                      <Select
                        styles={customStyles}
                        name="signup-country"
                        placeholder={translate('signup.fillDetails.country')}
                        options={countriesCodes}
                        className={classes.selectDropdown}
                        value={countriesCodes.find(
                          country => country.value === form.fields.country
                        )}
                        onChange={selectedOption => {
                          updateField({
                            target: {
                              name: 'country',
                              value: selectedOption.value || 'US',
                            },
                          });
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <div className={classes.inputWrapper}>
                        <FormField
                          data-test-id="signup-postal-code"
                          name="postal_code"
                          placeholder={translate(
                            'signup.fillDetails.postalCode'
                          )}
                          field={{
                            value: form.fields.postal_code,
                            error: form.errors.postal_code,
                          }}
                          onChange={updateField}
                          onBlur={validateField}
                          padding={false}
                        />
                      </div>
                    </FormControl>
                  </Grid>
                </Grid>
                {/* password */}
                <Grid
                  item
                  container
                  justify="flex-start"
                  alignItems="center"
                  spacing={16}
                >
                  <Grid item xs={11}>
                    <FormField
                      data-test-id="signup-password"
                      name="password"
                      type="password"
                      placeholder={translate('signup.fillDetails.password')}
                      field={{
                        value: form.fields.password,
                        error: form.errors.password,
                      }}
                      onChange={updateField}
                      onBlur={validateField}
                      icon=<DarkLockIcon />
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  justify="flex-start"
                  alignItems="center"
                  spacing={16}
                >
                  <Grid item xs={11}>
                    <FormField
                      data-test-id="signup-confirm-password"
                      name="confirm_password"
                      type="password"
                      placeholder={translate(
                        'signup.fillDetails.confirmPassword'
                      )}
                      field={{
                        value: form.fields.confirm_password,
                        error: form.errors.confirm_password,
                      }}
                      onChange={updateField}
                      onBlur={validateField}
                      icon=<DarkLockIcon />
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  justify="center"
                  alignItems="center"
                  spacing={16}
                >
                  <Grid item xs={10}>
                    <ActionButton
                      data-test-id="signup-submit-btn"
                      type="submit"
                      inProgress={form.submitting}
                      inProgressText={translate(
                        'components.selectAccount.submitting'
                      )}
                      className={classes.actionButton}
                    >
                      <Translate value="common.submit" />
                    </ActionButton>
                  </Grid>
                </Grid>
                {!form.succeeded && form.error && (
                  <ErrorMessage error={form.error} />
                )}
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default withRouter(
  withStyles(fillDetailsStyles, { name: 'FillDetails' })(FillDetails)
);
