import { call, put, takeEvery, select, all, delay } from 'redux-saga/effects';
import update from 'immutability-helper';
import { translate } from 'react-i18nify';

import * as AppTypes from '../actions/app';
import * as Types from '../actions/signup';
import { loginFetch } from '../actions/login';
import {
  updateSubscriptions,
  storeSubscriptions,
  storeSerials,
} from '../actions/account';
import * as ActivateTypes from '../actions/activate';
import Auth from '../lib/Auth';
import Api from './Api';
import * as Env from '../lib/Env';

// TODO: add more validation
function validateField(field, value) {
  return {
    ...(!value && { [field]: 'Required' }),
  };
}

const emailForm = {
  submitting: false,
  succeeded: false,
  fields: {
    email: '',
  },
  errors: {},
};

const userForm = {
  submitting: false,
  succeeded: false,
  fields: {
    email: '',
    first_name: '',
    last_name: '',
    company_name: '',
    phone_number: '',
    country: 'US',
    postal_code: '',
    password: '',
    confirm_password: '',
    region: 'us-east-1',
  },
  error: '',
  errors: {},
};

const defaultState = {
  processing: false,
  submitting: false,
  validDetails: false,
  page: 1,
  msp: false,
  email: '',
  provisioned_accounts: [],
  license: {
    submitting: false,
    succeeded: false,
    error: '',
  },
  emailForm,
  userForm,
  userLogin: {
    submitting: false,
    succeeded: true,
    error: '',
  },
  activation: {
    submitting: false,
    error: null,
  },
  account: '',
  accounts: [],
  user: '',
  login: false,
};

export default (
  state = {
    ...defaultState,
  },
  action
) => {
  switch (action.type) {
    case Types.SUBMIT_EMAIL:
      return update(state, {
        emailForm: {
          succeeded: { $set: false },
          submitting: { $set: true },
          errors: { $set: {} },
        },
      });
    case Types.SUBMIT_EMAIL_SUCCESS:
      return update(state, {
        emailForm: {
          succeeded: { $set: true },
          submitting: { $set: false },
          errors: { $set: {} },
        },
      });
    case Types.SUBMIT_EMAIL_FAILURE:
      return update(state, {
        emailForm: {
          succeeded: { $set: false },
          submitting: { $set: false },
          errors: {
            email: { $set: action.error || 'Email verification failed' },
          },
        },
      });
    case Types.UPDATE_EMAIL_ADDRESS:
      return update(state, {
        emailForm: {
          fields: {
            email: { $set: action.email },
          },
          errors: {
            email: { $set: '' },
          },
        },
      });
    case Types.TOGGLE_LOGIN:
      return {
        ...state,
        login: true,
        emailForm,
      };
    case Types.SET_LOGIN:
      return {
        ...state,
        login: action.value,
        emailForm,
      };
    case Types.SIGNUP_CANCEL_AGREEMENT:
      return {
        ...state,
        page: 1,
      };
    case Types.SIGNUP_SHOW_AGREEMENT:
      return {
        ...state,
        page: 2,
      };
    case Types.START_TRIAL:
    case Types.ACTIVATE_TRIAL:
      return update(state, {
        license: {
          submitting: { $set: true },
          succeeded: { $set: false },
          error: { $set: '' },
        },
      });
    case Types.START_TRIAL_SUCCESS:
      return {
        ...defaultState,
      };
    case Types.START_TRIAL_FAILURE:
      return update(state, {
        license: {
          submitting: { $set: false },
          succeeded: { $set: false },
          error: { $set: 'Failed to start trial' },
        },
      });
    case Types.UPDATE_FORM_FIELDS:
      return update(state, {
        [action.form]: {
          fields: { $merge: action.fields },
          errors: {
            $unset: Object.keys(action.fields),
          },
        },
      });
    case Types.VALIDATE_FORM_FIELD:
      return update(state, {
        [action.form]: {
          errors: {
            $unset: [action.field],
            $merge: validateField(action.field, action.value),
          },
        },
      });
    case Types.FORM_SUBMIT_SUCCESS:
      return update(state, {
        [action.form]: {
          succeeded: { $set: true },
          submitting: { $set: false },
          error: { $set: '' },
        },
      });
    case Types.FORM_SUBMIT_FAILURE:
      return update(state, {
        [action.form]: {
          succeeded: { $set: false },
          submitting: { $set: false },
          error: { $set: action.error },
        },
      });
    case Types.FORM_SUBMIT_START:
      return update(state, {
        [action.form]: {
          succeeded: { $set: false },
          submitting: { $set: true },
          error: { $set: '' },
        },
      });
    case Types.UPDATE_FORM_ERRORS:
      return {
        ...state,
        [action.form]: update(state[action.form], {
          errors: { $merge: action.errors },
        }),
      };
    case Types.SIGNUP_STORE_ACCOUNT:
      return {
        ...state,
        page: 2,
        account: action.account,
      };
    case Types.STORE_SIGNUP_SESSION_INFO: {
      const { accounts, dtoken, provisioned_accounts } = action;

      return {
        ...state,
        account:
          accounts.length === 1 ? accounts[0].account_id : dtoken.account,
        accounts,
        user_id: dtoken.sub,
        msp: action.msp,
        sso: dtoken,
        provisioned_accounts,
      };
    }
    case Types.CLEAR_SIGNUP_SESSION_INFO:
      return {
        ...state,
        account: '',
        msp: false,
        accounts: [],
        user_id: '',
        sso: {},
      };
    case Types.SIGNUP_USER_LOGIN:
      return {
        ...state,
        userLogin: {
          ...state.userLogin,
          submitting: true,
          error: '',
        },
      };
    case Types.SIGNUP_USER_LOGIN_SUCCESS:
      return {
        ...state,
        userLogin: {
          ...state.userLogin,
          submitting: false,
          succeeded: true,
          error: '',
        },
      };
    case Types.SIGNUP_USER_LOGIN_FAILURE:
      return {
        ...state,
        userLogin: {
          ...state.userLogin,
          submitting: false,
          succeeded: false,
          error: action.error || 'Login failed',
        },
      };
    case ActivateTypes.ACTIVATE_STORE_SSO_TOKEN: {
      const re = /Partner\s*$/g;
      const msp = action.dtoken.accounts.some(a => a.type && a.type.match(re));
      const provisioned_accounts = action.provisioned_accounts;

      if (msp) {
        return state;
      }

      const services = action.dtoken.services.reduce(
        (obj, account) => ({
          ...obj,
          ...(account.service_id === 17 && { [account.account_id]: true }),
        }),
        {}
      );
      const accounts = action.dtoken.accounts.filter(
        account =>
          !provisioned_accounts.includes(Number(account.account_id)) &&
          !(account.type && account.type.match(re))
      );

      return {
        ...state,
        accounts,
        msp,
        user_id: action.dtoken.sub,
        sso: action.dtoken,
      };
    }
    case Types.SIGNUP_LINK_PURCHASE:
      return update(state, {
        activation: {
          submitting: { $set: true },
          error: { $set: null },
        },
      });
    case Types.SIGNUP_LINK_PURCHASE_FAILURE:
      return update(state, {
        activation: {
          submitting: { $set: false },
          error: { $set: action.error },
        },
      });
    default:
      return state;
  }
};

async function login(credentials) {
  return loginFetch(credentials).then(response => response.json());
}

function* authenticateUser(credentials) {
  const sessionInfo = yield call(login, credentials);
  if ('code' in sessionInfo && sessionInfo['code'] !== 200) {
    throw sessionInfo;
  }
  Auth.clear_local_storage();
  const dtoken = Auth.setup_sso(
    sessionInfo.id_token,
    sessionInfo.refresh_token
  );
  return { dtoken, provisioned_accounts: sessionInfo.provisioned_accounts };
}

function* refreshToken(action) {
  const token = Auth.get_refresh_token();
  if (!token) {
    const response = yield call(Api.auth.cookie_refresh);
    Auth.setup_support(response.payload);
    Auth.store_token(response.token);
    yield all([
      put(updateSubscriptions(response.subscriptions, response.account_subs)),
      put(storeSubscriptions(response.subscriptions, response.account_subs)),
      put(storeSerials(response.serials)),
    ]);
    return;
  }
  const response = yield call(Api.auth.refresh, token);
  Auth.setup_sso(response.sso.id_token, response.sso.refresh_token);
  Auth.store_token(response.jwe);
  yield all([
    put(updateSubscriptions(response.subscriptions, response.account_subs)),
    put(storeSubscriptions(response.subscriptions, response.account_subs)),
    put(storeSerials(response.serials)),
  ]);
}

function* startTrial(action) {
  try {
    const store = yield select();
    const { account, user_id, userForm } = store.signup;

    const region = userForm.fields.region;

    const response = yield call(Api.accounts.activateTrial, account, {
      user_id,
      region,
    });

    if (response.status === 409) {
      yield put(Types.startTrialFailure('Account has already used trial'));
    } else if (response.error) {
      yield put(Types.startTrialFailure());
    } else {
      let host = window.location.host;
      Auth.setup_region(region);
      const dest = Env.BCS_HOST[region];

      if (
        dest &&
        host.indexOf(dest) === -1 &&
        host.indexOf('localhost') === -1
      ) {
        window.location.href = `http://${dest}`;
        return;
      }

      yield refreshToken(action);
      action.history.push('/route_view');
      yield delay(5000);
      yield put(Types.startTrialSuccess());
    }
  } catch (e) {
    yield put(Types.startTrialFailure());
  }
}

function* activateTrial(action) {
  try {
    yield startTrial(action);
  } catch (e) {
    yield put(Types.startTrialFailure());
  }
}

const userErrors = {
  400: 'Failed to create user',
  401: 'Invalid token',
  409: 'Account with this email already exists',
  500: 'Failed to create user',
  502: 'Failed to create user',
};

function* createUser(action) {
  try {
    const store = yield select();
    const { fields } = store.signup.userForm;
    yield put({
      type: Types.FORM_SUBMIT_START,
      form: 'userForm',
    });

    const result = yield call(Api.users.create, {
      ...fields,
      name: `${fields.first_name} ${fields.last_name}`,
    });

    if (result.status !== 201) {
      yield put(
        Types.formSubmitFailure(
          'userForm',
          result.message || userErrors[result.status] || 'Failed to create user'
        )
      );
      return;
    }

    yield put(Types.formSubmitSuccess('userForm'));
  } catch (e) {
    yield put(Types.formSubmitFailure('userForm', 'Failed to create user'));
  }
}

function* validateUserInfo() {
  try {
    const store = yield select();
    const { fields } = store.signup.userForm;
    let errors = {
      ...(fields.password !== fields.confirm_password && {
        password: 'Passwords must match',
        confirm_password: 'Passwords must match',
      }),
      ...(!fields.phone_number.match(/^[^\-][\d -]+[^\-]$/) && {
        phone_number: 'Invalid phone number',
      }),
      ...(!fields.postal_code.match(/^[a-z0-9 ]+$/i) && {
        postal_code: 'Invalid postal code',
      }),
    };
    errors = Object.keys(fields).reduce(
      (obj, field) => ({
        ...obj,
        ...validateField(field, fields[field]),
      }),
      errors
    );

    if (Object.keys(errors).length) {
      yield put(Types.updateFormErrors('userForm', errors));
      return;
    }
    yield createUser();
  } catch (e) {
    yield put(Types.formSubmitFailure('userForm', 'Failed to create user'));
  }
}

export function isBlockedEmail(str) {
  var blocked = [
    'gmail.',
    'hotmail.',
    'yahoo.',
    'aol.',
    'abc.',
    'xyz.',
    'pqr.',
    'rediffmail.',
    'live.',
    'outlook.',
    'me.com',
    'msn.',
    'ymail.',
  ];
  for (var i = 0; i < blocked.length; i++) {
    if (str.indexOf(blocked[i]) !== -1) {
      return true;
    }
  }
  return false;
}

function* submitEmail() {
  try {
    const store = yield select();
    const { email } = store.signup.emailForm.fields;
    if (email.match(/[^@]+@[^.]+\..+/) && !isBlockedEmail(email)) {
      const response = yield call(Api.users.verify_email, email);

      if (response.status === 409) {
        yield put(Types.submitEmailFailure('Email already registered'));
      } else if (response.error) {
        yield put(Types.submitEmailFailure());
      } else {
        yield put(Types.submitEmailSuccess());
      }
    } else {
      yield put(
        Types.submitEmailFailure(
          'Invalid email.Please enter a valid Business Email.'
        )
      );
    }
  } catch (e) {
    yield put(Types.submitEmailFailure());
  }
}

function* checkSubscriptions(accountId, serial, jwe) {
  for (let i = 0; i < 24; i += 1) {
    try {
      const apiResponse = yield call(Api.accounts.subscriptions, accountId, {
        serial,
        jwe,
      });
      return apiResponse;
    } catch (err) {
      if (i < 24) {
        yield delay(10000);
      }
    }
  }
  throw new Error('Failed to activate');
}

function* linkPurchase(action) {
  try {
    const store = yield select();
    const result = yield call(Api.accounts.activate, store.account.selected, {
      accountId: store.account.selected,
      serial: action.serial,
      code: action.code,
      jwe: Auth.get_token(),
    });
    if (result.timeout) {
      yield call(
        checkSubscriptions,
        store.activate.info.accountId,
        store.activate.info.serial,
        Auth.get_token()
      );
    }
    window.location.reload(); // eslint-disable-line
  } catch (e) {
    const { error } = e;
    if (error.response) {
      if (error.response.status == 500) {
        yield put({
          type: Types.SIGNUP_LINK_PURCHASE_FAILURE,
          error: translate('errors.500'),
        });
      }
      yield put({
        type: Types.SIGNUP_LINK_PURCHASE_FAILURE,
        error: error.response.data.message,
      });
    } else if (error.request) {
      yield put({
        type: Types.SIGNUP_LINK_PURCHASE_FAILURE,
        error: translate('errors.503'),
      });
    } else {
      yield put({
        type: Types.SIGNUP_LINK_PURCHASE_FAILURE,
        error: translate('errors.500'),
      });
    }
  }
}

function* userLogin(action) {
  try {
    const { dtoken, provisioned_accounts } = yield authenticateUser({
      username: action.email,
      password: action.password,
    });
    const re = /Partner\s*$/g;
    const msp = dtoken.accounts.some(a => a.type && a.type.match(re));

    const accounts = dtoken.accounts.filter(
      account =>
        !provisioned_accounts[Number(account.account_id)] &&
        !(account.type && account.type.match(re))
    );
    if (msp) {
      yield put({
        type: Types.SIGNUP_USER_LOGIN_FAILURE,
        error: translate('signup.mspError'),
      });
    } else if (!accounts.length) {
      yield put({
        type: Types.SIGNUP_USER_LOGIN_FAILURE,
        error: 'No eligible accounts',
      });
    } else {
      yield all([
        put({ type: Types.SIGNUP_USER_LOGIN_SUCCESS }),
        put(
          Types.storeSessionInfo(dtoken, accounts, msp, provisioned_accounts)
        ),
      ]);
    }
  } catch (e) {
    console.log('the failure: ', e);
    if ('code' in e && e['code'] == 400) {
      yield put({
        type: Types.SIGNUP_USER_LOGIN_FAILURE,
        error: translate('signup.redirect'),
      });
    } else {
      yield put({
        type: Types.SIGNUP_USER_LOGIN_FAILURE,
        error: 'Login failed',
      });
    }
  }
}

function* newUserTrial(action) {
  try {
    const store = yield select();
    const { fields } = store.signup.userForm;

    yield userLogin({
      email: fields.email,
      password: fields.password,
    });

    yield startTrial(action);
  } catch (e) {
    console.log('the failure: ', e);
    yield put(Types.startTrialFailure());
  }
}

export function* signupReducerFlow() {
  yield takeEvery(Types.SUBMIT_EMAIL, submitEmail);
  yield takeEvery(Types.START_TRIAL, newUserTrial);
  yield takeEvery(Types.ACTIVATE_TRIAL, activateTrial);
  yield takeEvery(Types.SUBMIT_USER_INFO, validateUserInfo);
  yield takeEvery(Types.SIGNUP_LINK_PURCHASE, linkPurchase);
  yield takeEvery(Types.SIGNUP_USER_LOGIN, userLogin);
}
