import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Translate, translate } from 'react-i18nify';

import Paper from './CudaPaper';

import BlockedRequestBox from '../containers/dashboard/BlockedRequestBox';
import MalwarePreventionBox from '../containers/dashboard/MalwarePreventionBox';
import PhishingPreventionBox from '../containers/dashboard/PhishingPreventionBox';
import ThreatPreventionBox from '../containers/dashboard/ThreatPreventionBox';
import CategoryStats from '../containers/dashboard/CategoryStats';
import SubscriptionModule from '../containers/overview/SubscriptionModule';
import LicenseModule from '../containers/overview/LicenseModule';

const styles = theme => ({
  paper: {
    border: '1px solid #DDDDDD',
  },
  paperTitle: {
    padding: '20px 20px 0 20px',
  },
  menu: {
    width: '150px',
  },
  button: {
    float: 'right',
  },
  statBox: {
    padding: '35px 0px',
  },
  catBox: {
    marginTop: '10px',
  },
});

class WFDashboardComponent extends Component {
  componentDidMount() {
    this.props.initData();
  }

  options = [
    {
      key: 'day',
      label: translate('shared.ranges.day'),
    },
    {
      key: 'week',
      label: translate('shared.ranges.week'),
    },
    {
      key: 'fortnight',
      label: translate('shared.ranges.fortnight'),
    },
  ];

  render() {
    const { classes, getSubscription, msp } = this.props;
    const { options } = this;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={8}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              data-test-id="malwarePreventionSection"
            >
              <Paper
                titleClasses={[classes.paperTitle]}
                elevation={0}
                className={classes.paper}
                title={
                  <span>
                    <Translate value="components.dashboard.malwarePrevention" />
                  </span>
                }
              >
                <div
                  className={classes.statBox}
                  data-test-id="malwarePreventionBox"
                >
                  <MalwarePreventionBox
                    range={options[0].key}
                    hideTitle={true}
                    inlineText={true}
                  />
                </div>
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              data-test-id="threatPreventionSection"
            >
              <Paper
                titleClasses={[classes.paperTitle]}
                elevation={0}
                className={classes.paper}
                title={
                  <span>
                    <Translate value="components.dashboard.webThreats" />
                  </span>
                }
              >
                <div
                  className={classes.statBox}
                  data-test-id="threatPreventionBox"
                >
                  <ThreatPreventionBox
                    range={options[0].key}
                    hideTitle={true}
                    inlineText={true}
                  />
                </div>
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              data-test-id="phishingPreventionSection"
            >
              <Paper
                titleClasses={[classes.paperTitle]}
                elevation={0}
                className={classes.paper}
                title={
                  <span>
                    <Translate value="components.dashboard.phishingPrevention" />
                  </span>
                }
              >
                <div
                  className={classes.statBox}
                  data-test-id="phishingPreventionBox"
                >
                  <PhishingPreventionBox
                    range={options[0].key}
                    hideTitle={true}
                    inlineText={true}
                  />
                </div>
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              data-test-id="blockedRequestSection"
            >
              <Paper
                titleClasses={[classes.paperTitle]}
                title={<span>Blocked Requests</span>}
                elevation={0}
                className={classes.paper}
              >
                <div
                  className={classes.statBox}
                  data-test-id="blockedRequestBox"
                >
                  <BlockedRequestBox
                    range={options[0].key}
                    hideTitle={true}
                    inlineText={true}
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          {!msp && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <SubscriptionModule initData={getSubscription} />
              </Grid>
            </Grid>
          )}
          {!msp && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <LicenseModule initData={getSubscription} />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2} className={classes.catBox}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper className={classes.paper} elevation={0}>
                <CategoryStats displayBlockRequest={false} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

WFDashboardComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  initData: PropTypes.func.isRequired,
  getSubscription: PropTypes.func.isRequired,
};

export default withStyles(styles, { name: 'WFDashboard' })(
  WFDashboardComponent
);
