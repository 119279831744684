import { all, put, takeEvery } from 'redux-saga/effects';
import * as Types from '../actions/login';
import Auth from '../lib/Auth';
import { retrieveAccount, storeUserName } from '../actions/account';

export default (
  state = {
    isProcessing: false,
    error: '',
    errorCode: '',
  },
  action
) => {
  switch (action.type) {
    case Types.LOGIN_REQUEST:
      return {
        ...state,
        isProcessing: true,
      };
    case Types.LOGIN_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        error: '',
      };
    case Types.LOGIN_FAILURE:
      return {
        ...state,
        isProcessing: false,
        error: action.error,
        errorCode: action.errorCode,
      };
    case Types.LOGIN_MFA_REQUIRED:
      return {
        ...state,
        isProcessing: false,
        error: action.error,
        errorCode: '301',
      };
    case Types.LOGIN_RESTRICT:
      return {
        ...state,
        isProcessing: false,
        error: action.error,
        errorCode: '406',
      };
    default:
      return state;
  }
};

function* loginSuccess() {
  const dtoken = Auth.get_sso_token();
  console.log('the dtoken: ', dtoken);
  yield all([
    put(
      retrieveAccount(
        dtoken.email,
        dtoken.account,
        dtoken.accounts,
        dtoken.services,
        dtoken.sub,
        dtoken.zoneinfo || dtoken.timezone
      )
    ),
    put(storeUserName(dtoken.name)),
  ]);
}

export function* loginReducerFlow() {
  yield takeEvery(Types.LOGIN_SUCCESS, loginSuccess);
}
