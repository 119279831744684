import { connect } from 'react-redux';
import { translate } from 'react-i18nify';
import ChipInput from '../../components/ChipInput';
import {
  selectAllowIndex,
  changeAllowDomains,
  badValueDomainError,
} from '../../actions/basic_filtering';

import { Mixpanel } from '../../lib/Mixpanel';

const mapStateToProps = (state, ownProps) => ({
  inputValue: state.basic_filtering.exceptionConfig.allowDomains,
  selectedIndex: state.basic_filtering.exceptionConfig.allowSelectedIndex,
  placeholder: translate('components.basicFiltering.allowPlaceholder'),
  iconClass: ownProps.iconClass,
  icon: ownProps.icon,
});

const mapDispatchToProps = dispatch => ({
  onSelectIndex: index => {
    dispatch(selectAllowIndex(index));
  },
  onChange: value => {
    Mixpanel.track(
      'DNS Filtering / Add Location Dialog / Step 2-Allowed Domains',
      {}
    );
    const domainRegex = /^[a-zA-Z0-9\-\.]+$/i;
    const domainRegexIPAddressv4 = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    const domainRegexIPAddressv6 = /^((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*::((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*|((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4})){7}$/g;
    for (let i = 0; i < value.length; i++) {
      if (!domainRegex.test(value[i])) {
        dispatch(badValueDomainError());
        return false;
      }
      if (
        domainRegexIPAddressv4.test(value[i]) ||
        domainRegexIPAddressv6.test(value[i])
      ) {
        dispatch(badValueDomainError());
        return false;
      }
    }
    dispatch(changeAllowDomains(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChipInput);
