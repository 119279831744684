import { connect } from 'react-redux';
import { translate } from 'react-i18nify';
import SmbAuditLog from '../components/AuditLog';
import { initData, fetchData, sortData } from '../actions/audit_log';

const mapStateToProps = (state, ownProps) => ({
  accountNames: state.account.accountNames,
  logs: state.audit_log.logs,
  loading: state.audit_log.loading,
  timezone: state.account.time_zone,
  logCount: state.audit_log.logCount,
  placeholder: translate('components.audit.smb_search_placeholder'),
  columns: {
    account: false,
  },
  catMapping: state.basic_filtering.catMapping,
  catV2Mapping: state.basic_filtering.catV2Mapping,
  catV3Mapping: state.basic_filtering.catV3Mapping,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  initData: () => {
    dispatch(initData({ smb: true }));
  },
  fetchLogs: params => {
    dispatch(fetchData(params, true));
  },
  sortLogs: (orderBy, order, specialMap) => {
    dispatch(sortData(orderBy, order, specialMap, true));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmbAuditLog);
