export const AF_APPLY_SEARCH_FILTER = 'AF_APPLY_SEARCH_FILTER';
export const AF_GET_RULESETS = 'AF_GET_RULESETS';
export const AF_ADD_RULE = 'AF_ADD_RULE';
export const AF_CHANGE_DOMAIN = 'AF_CHANGE_DOMAIN';
export const AF_CHANGE_URL = 'AF_CHANGE_URL';
export const AF_CHANGE_AD = 'AF_CHANGE_AD';
export const AF_CLOSE_EDIT_RULE = 'AF_CLOSE_EDIT_RULE';
export const AF_CLOSE_ADD_RULE = 'AF_CLOSE_ADD_RULE';
export const AF_DELETE_RULE = 'AF_DELETE_RULE';
export const AF_DELETE_RULESET_RULE = 'AF_DELETE_RULESET_RULE';
export const AF_EDIT_RULE = 'AF_EDIT_RULE';
export const AF_EDIT_RULE_ERROR = 'AF_EDIT_RULE_ERROR';
export const AF_CREATE_RULE_ERROR = 'AF_CREATE_RULE_ERROR';
export const AF_OPEN_URL_LOGGING = 'AF_OPEN_URL_LOGGING';
export const AF_TOGGLE_URL_LOGGING = 'AF_TOGGLE_URL_LOGGING';
export const AF_CLOSE_URL_LOGGING = 'AF_CLOSE_URL_LOGGING';
export const AF_ADD_URL_LOGGING = 'AF_ADD_URL_LOGGING';
export const AF_OPEN_ADD_RULE = 'AF_OPEN_ADD_RULE';
export const AF_OPEN_SAFE_SEARCH = 'AF_OPEN_SAFE_SEARCH';
export const AF_ADD_SAFESEARCH = 'AF_ADD_SAFESEARCH';
export const AF_TOGGLE_SAFESEARCH = 'AF_TOGGLE_SAFESEARCH';
export const AF_CLOSE_SAFESEARCH = 'AF_CLOSE_SAFESEARCH';
export const AF_UPDATE_SELECTED_CATEGORIES = 'AF_UPDATE_SELECTED_CATEGORIES';
export const GET_RULESET_FAILURE = 'GET_RULESET_FAILURE';
export const GET_RULESET_SUCCESS = 'GET_RULESET_SUCCESS';
export const INIT_ADVANCED_FILTER = 'INIT_ADVANCED_FILTER';
export const AF_OPEN_EVERYONE_EDIT_RULE = 'AF_OPEN_EVERYONE_EDIT_RULE';
export const AF_EDIT_EVERYONE_RULE = 'AF_EDIT_EVERYONE_RULE';
export const AF_CLOSE_EVERYONE_RULE = 'AF_CLOSE_EVERYONE_RULE';
export const AF_OPEN_EDIT_RULE = 'AF_OPEN_EDIT_RULE';
export const AF_SEARCH_INPUT_ONCHANGE = 'AF_SEARCH_INPUT_ONCHANGE';
export const AF_SEARCH_FIELD_ONCHANGE = 'AF_SEARCH_FIELD_ONCHANGE';
export const AF_RESET_SEARCH = 'AF_RESET_SEARCH';
export const AF_BUTTON_MENU_SELECT = 'AF_BUTTON_MENU_SELECT';
export const AF_START_TIME_ERROR = 'AF_START_TIME_ERROR';
export const SELECT_AF_ACTION = 'SELECT_AF_ACTION';
export const SELECT_AF_TIME_ACTION = 'SELECT_AF_TIME_ACTION';
export const AF_SELECT_DOW = 'AF_SELECT_DOW';
export const AF_SELECT_TIME_RANGE = 'AF_SELECT_TIME_RANGE';
export const SELECT_AF_EVERYONE_ACTION = 'SELECT_AF_EVERYONE_ACTION';
export const SELECT_AF_TYPE = 'SELECT_AF_TYPE';
export const SELECT_AF_INPUT_INDEX = 'SELECT_AF_INPUT_INDEX';
export const REORDER_RULES = 'REORDER_RULES';
export const REORDER_RULESETS = 'REORDER_RULESETS';
export const UPDATE_RULE_ORDER = 'UPDATE_RULE_ORDER';

export const SUBMIT_RULE_ORDER = 'SUBMIT_RULE_ORDER';
export const SUBMIT_RULE_ORDER_SUCCESS = 'SUBMIT_RULE_ORDER_SUCCESS';
export const SUBMIT_RULE_ORDER_FAILURE = 'SUBMIT_RULE_ORDER_FAILURE';

export const SUBMIT_RULESET_ORDER = 'SUBMIT_RULESET_ORDER';
export const SUBMIT_RULESET_ORDER_SUCCESS = 'SUBMIT_RULESET_ORDER_SUCCESS';
export const SUBMIT_RULESET_ORDER_FAILURE = 'SUBMIT_RULESET_ORDER_FAILURE';

export const SUBMIT_RULES_REORDER = 'SUBMIT_RULES_REORDER';
export const SUBMIT_RULES_REORDER_SUCCESS = 'SUBMIT_RULES_REORDER_SUCCESS';
export const SUBMIT_RULES_REORDER_FAILURE = 'SUBMIT_RULES_REORDER_FAILURE';

export const SUBMIT_RULESETS_REORDER = 'SUBMIT_RULESETS_REORDER';
export const SUBMIT_RULESETS_REORDER_SUCCESS =
  'SUBMIT_RULESETS_REORDER_SUCCESS';
export const SUBMIT_RULESETS_REORDER_FAILURE =
  'SUBMIT_RULESETS_REORDER_FAILURE';

export const HIGHLIGHT_RULE = 'HIGHLIGHT_RULE';

export const GET_POLICY_SETTINNGS_SUCCESS = 'GET_POLICY_SETTINNGS_SUCCESS';

export const TYPE_OPTIONS = [
  {
    key: 'categories',
    label: 'Categories',
  },
  {
    key: 'domains',
    label: 'Domains',
  },
  {
    key: 'url',
    label: 'URLs',
  },
];

export const RULE_TYPES = {
  categories: 'category',
  domains: 'domain',
  url: 'url',
  jump: 'jump',
};

export const ACTION_OPTIONS = [
  {
    key: 'block',
    label: 'Block',
  },
  {
    key: 'allow',
    label: 'Allow',
  },
];

export const SCHEDULE_TIME_OPTIONS = [
  {
    key: 'no_constraint',
    label: 'No Constraint',
  },
  {
    key: 'time',
    label: 'Time',
  },
];

export const startTimeError = () => ({
  type: AF_START_TIME_ERROR,
});

export const applySearchFilter = () => ({
  type: AF_APPLY_SEARCH_FILTER,
});

export const resetSearchFilter = () => ({
  type: AF_RESET_SEARCH,
});

export const searchInputOnChange = value => ({
  type: AF_SEARCH_INPUT_ONCHANGE,
  value,
});

export const searchFieldOnChange = field => ({
  type: AF_SEARCH_FIELD_ONCHANGE,
  field,
});

export const setHighlightedRule = rule => ({
  type: HIGHLIGHT_RULE,
  rule,
});

export const init = () => ({
  type: INIT_ADVANCED_FILTER,
});

export const getRulesets = () => ({
  type: AF_GET_RULESETS,
});

const _formatRuleset = data => {
  let formatData = {
    userToRuleset: {},
    groupToRuleset: {},
    ruleset: data,
  };

  // This is listed as rules but they are really just jump rules
  // Which means they point to a ruleset servering as the group
  const jumpRules = data.rules || [];

  // Assume that there is only one user_id per rule and one group per rule
  for (let i = 0; i < jumpRules.length; i++) {
    if (jumpRules[i]['ruleset_jump'] && jumpRules[i]['user_ids'].length > 0) {
      formatData['userToRuleset'][jumpRules[i]['user_ids'][0]] =
        jumpRules[i]['ruleset_jump']['id'];
    } else if (
      jumpRules[i]['ruleset_jump'] &&
      jumpRules[i]['group_ids'].length > 0
    ) {
      formatData['userToRuleset'][jumpRules[i]['group_ids'][0]] =
        jumpRules[i]['ruleset_jump']['id'];
    } else if (
      jumpRules[i]['ruleset_jump'] &&
      jumpRules[i]['user_ids'].length === 0 &&
      jumpRules[i]['group_ids'].length === 0
    ) {
      formatData['groupToRuleset']['everyone'] =
        jumpRules[i]['ruleset_jump']['id'];
    }

    if (jumpRules[i]['ruleset_jump'] && jumpRules[i]['ruleset_jump']['rules']) {
      jumpRules[i]['ruleset_jump']['rules'].sort((a, b) => a.index - b.index);
    }
  }

  // Need to add a dummy rule for the case in which all
  // rules under everyone rule was deleted.
  if (!('everyone' in formatData['groupToRuleset'])) {
    formatData.ruleset.rules.push({
      id: 'everyone',
      user_ids: [],
      group_ids: [],
      ruleset_jump: { id: 'everyone', rules: [] },
    });
    formatData['groupToRuleset']['everyone'] = null;
  }

  if (formatData.ruleset.rules) {
    formatData.ruleset.rules.sort((a, b) => a.index - b.index);
  }

  return formatData;
};

export const getPolicySettingsSuccess = data => ({
  type: GET_POLICY_SETTINNGS_SUCCESS,
  data,
});

export const getRulesetSuccess = data => {
  const formatData = _formatRuleset(data);

  return {
    type: GET_RULESET_SUCCESS,
    userToRuleset: formatData.userToRuleset,
    groupToRuleset: formatData.groupToRuleset,
    ruleset: formatData.ruleset,
  };
};

export const getRulesetFailure = error => ({
  type: GET_RULESET_FAILURE,
  error,
});

export const addSafesearch = () => ({
  type: AF_ADD_SAFESEARCH,
});

export const addRule = () => ({
  type: AF_ADD_RULE,
});

export const selectButtonMenu = index => ({
  type: AF_BUTTON_MENU_SELECT,
  index,
});

export const toggleUrlLogging = () => ({
  type: AF_TOGGLE_URL_LOGGING,
});

export const toggleSafesearch = () => ({
  type: AF_TOGGLE_SAFESEARCH,
});

export const openUrlLogging = (
  selectedAD,
  enabled = false,
  disabledAD = false
) => ({
  type: AF_OPEN_URL_LOGGING,
  selectedAD,
  enabled,
  disabledAD,
});

export const closeUrlLogging = () => ({
  type: AF_CLOSE_URL_LOGGING,
});

export const addUrlLogging = () => ({
  type: AF_ADD_URL_LOGGING,
});

export const openSafesearch = (
  selectedAD,
  enabled = false,
  disabledAD = false
) => ({
  type: AF_OPEN_SAFE_SEARCH,
  selectedAD,
  enabled,
  disabledAD,
});

export const openAddRule = (selectedAD, disabled = false) => ({
  type: AF_OPEN_ADD_RULE,
  selectedAD,
  disabled,
});

export const closeSafesearch = () => ({
  type: AF_CLOSE_SAFESEARCH,
});

export const closeAddRule = () => ({
  type: AF_CLOSE_ADD_RULE,
});

export const openEveryoneEditRule = () => ({
  type: AF_OPEN_EVERYONE_EDIT_RULE,
});

export const AF_OPEN_EDIT_RULESET = 'AF_OPEN_EDIT_RULESET';
export const openEditRuleset = (selectedAD, rule) => ({
  type: AF_OPEN_EDIT_RULESET,
  selectedAD,
  rule,
});

export const AF_SAVE_TIME_CONSTRAINT = 'AF_SAVE_TIME_CONSTRAINT';
export const saveTimeConstraint = () => ({
  type: AF_SAVE_TIME_CONSTRAINT,
});

export const AF_TIME_EDIT_SUCCESS = 'AF_TIME_EDIT_SUCCESS';
export const saveTimeConstraintSuccess = () => ({
  type: AF_TIME_EDIT_SUCCESS,
});

export const AF_TIME_EDIT_FAILED = 'AF_TIME_EDIT_FAILED';
export const saveTimeConstraintFailed = () => ({
  type: AF_TIME_EDIT_FAILED,
});

export const AF_GET_MODIFIER_SUCCESS = 'AF_GET_MODIFIER_SUCCESS';
export const getModifierSuccess = modifiers => {
  let mods = {};
  for (let i = 0; i < modifiers.length; i++) {
    mods[modifiers[i]['related_object_id']] = modifiers[i];
  }

  console.log('the mods: ', mods);
  return {
    type: AF_GET_MODIFIER_SUCCESS,
    modifiers: mods,
  };
};

export const AF_OPEN_EDIT_TIME_CONSTRAINT = 'AF_OPEN_EDIT_TIME_CONSTRAINT';
export const openTimeConstraint = (selectedAD, rule) => ({
  type: AF_OPEN_EDIT_TIME_CONSTRAINT,
  selectedAD,
  rule,
});

export const AF_OPEN_CLOSE_TIME_CONSTRAINT = 'AF_OPEN_CLOSE_TIME_CONSTRAINT';
export const closeTimeConstraint = () => ({
  type: AF_OPEN_CLOSE_TIME_CONSTRAINT,
});

export const selectTime = (name, value) => ({
  type: AF_SELECT_TIME_RANGE,
  name,
  value,
});

export const selectDow = (name, value) => ({
  type: AF_SELECT_DOW,
  name,
  value,
});

export const openEditRule = (selectedAD, rule) => ({
  type: AF_OPEN_EDIT_RULE,
  selectedAD,
  rule,
});

export const deleteRulesetRule = rule_id => ({
  type: AF_DELETE_RULESET_RULE,
  rule_id,
});

export const deleteRule = (ruleset_id, id) => ({
  type: AF_DELETE_RULE,
  ruleset_id,
  id,
});

export const editEveryoneRule = rule => ({
  type: AF_EDIT_EVERYONE_RULE,
  rule,
});

export const closeEveryoneRule = () => ({
  type: AF_CLOSE_EVERYONE_RULE,
});

export const editRule = () => ({
  type: AF_EDIT_RULE,
});

export const editError = error => ({
  type: AF_EDIT_RULE_ERROR,
  error,
});

export const createError = error => ({
  type: AF_CREATE_RULE_ERROR,
  error,
});

export const closeRule = () => ({
  type: AF_CLOSE_EDIT_RULE,
});

export const selectEveryoneAction = index => ({
  type: SELECT_AF_EVERYONE_ACTION,
  index,
});

export const selectTimeAction = index => ({
  type: SELECT_AF_TIME_ACTION,
  index,
});

export const selectAction = index => ({
  type: SELECT_AF_ACTION,
  index,
});

export const selectIndex = index => ({
  type: SELECT_AF_INPUT_INDEX,
  index,
});

export const selectType = index => ({
  type: SELECT_AF_TYPE,
  index,
});

export const onChangeDomain = value => ({
  type: AF_CHANGE_DOMAIN,
  value,
});

export const onChangeUrl = value => ({
  type: AF_CHANGE_URL,
  value,
});

export const onChangeAD = value => ({
  type: AF_CHANGE_AD,
  value,
});

export const updatedSelectedCategories = (selected, added, removed) => ({
  type: AF_UPDATE_SELECTED_CATEGORIES,
  selected,
  added,
  removed,
});

export const reorderRules = (dragIndex, hoverIndex, parentIndex) => ({
  type: REORDER_RULES,
  dragIndex,
  hoverIndex,
  parentIndex,
});

// submit changes that have already been made on the UI.  In this instance, the ordering
// of the rules in the state is correct, but the indexes are not updated properly
export const submitRulesReorder = (
  id,
  index,
  parentIndex,
  originalIndex,
  rulesetId
) => ({
  type: SUBMIT_RULES_REORDER,
  id,
  index,
  parentIndex,
  originalIndex,
  rulesetId,
});
export const submitRulesReorderSuccess = parentIndex => ({
  type: SUBMIT_RULES_REORDER_SUCCESS,
  parentIndex,
});
export const submitRulesReorderFailure = () => ({
  type: SUBMIT_RULES_REORDER_FAILURE,
});
// end rule submit

// reorder rulesets purely on the UI using the drag and drop
export const reorderRulesets = (dragIndex, hoverIndex) => ({
  type: REORDER_RULESETS,
  dragIndex,
  hoverIndex,
});
// end reorder

// submit change in ruleset order that was done with drag and drop.
// the rulesets order will already be in the state without index being updated
export const submitRulesetsReorder = (id, index, originalIndex, rulesetId) => ({
  type: SUBMIT_RULESETS_REORDER,
  id,
  index,
  originalIndex,
  rulesetId,
});
export const submitRulesetsReorderSuccess = () => ({
  type: SUBMIT_RULESETS_REORDER_SUCCESS,
});
export const submitRulesetsReorderFailure = () => ({
  type: SUBMIT_RULESETS_REORDER_FAILURE,
});
// end ruleset submit

// update the value of the rule in the input box in table, but don't save anything
export const updateRuleOrder = (index, order, parentIndex) => ({
  type: UPDATE_RULE_ORDER,
  index,
  order,
  parentIndex,
});
// end update

// directly submit a new index for a rule.  if no index is specified, the value of
// "index" for the rule in the state will be used
export const submitRuleOrder = (
  id,
  originalIndex,
  parentIndex,
  index,
  rulesetId
) => ({
  type: SUBMIT_RULE_ORDER,
  id,
  originalIndex,
  parentIndex,
  index,
  rulesetId,
});
export const submitRuleOrderSuccess = () => ({
  type: SUBMIT_RULE_ORDER_SUCCESS,
});
export const submitRuleOrderFailure = () => ({
  type: SUBMIT_RULE_ORDER_FAILURE,
});
// end rule submit

// directly submit a new index for a ruleset.  "index" will be updated as the new
// index for the rule at orgiinalIndex
export const submitRulesetOrder = (id, originalIndex, index, rulesetId) => ({
  type: SUBMIT_RULESET_ORDER,
  id,
  index,
  originalIndex,
  rulesetId,
});
export const submitRulesetOrderSuccess = () => ({
  type: SUBMIT_RULESET_ORDER_SUCCESS,
});
export const submitRulesetOrderFailure = () => ({
  type: SUBMIT_RULESET_ORDER_FAILURE,
});
// end ruleset submit
